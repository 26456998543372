import AusShepherd from "../../assets/pods/australianShepherd@2x.png";
import Corgi from "../../assets/pods/corgi@2x.png";
import Greyhound from "../../assets/pods/ItalianGreyhound@2x.png";
import Akita from "../../assets/pods/akita@2x.png";
import ChowChow from "../../assets/pods/chowchow@2x.png";
import {
  Pod1,
  Pod2,
  Pod3,
  Pod4,
  Pod5,
  PodsWrapper,
} from "./AdoptSection.styled";

const Pods = () => {
  return (
    <PodsWrapper>
      <Pod1>
        <img src={Akita} alt="diamond-dogami-akita" />
      </Pod1>
      <Pod2>
        <img src={Greyhound} alt="silver-dogami-greyhound" />
      </Pod2>
      <Pod3>
        <img src={Corgi} alt="diamond-dogami-corgi" />
      </Pod3>
      <Pod4>
        <img src={AusShepherd} alt="gold-dogami-australian-shepherd" />
      </Pod4>
      <Pod5>
        <img src={ChowChow} alt="bronze-dogami-chowchow" />
      </Pod5>
    </PodsWrapper>
  );
};

export default Pods;
