import { MarketplaceTab } from "./MarketplaceTab";

import { MoreTab } from "./MoreTab";

import { AcademyTab } from "./AcademyTab";

import { DogaTab } from "./DogaTab";

export default function HeaderLinks() {
  return (
    <>
      <div className="flex h-full w-full items-center justify-between">
        <section className="header-shadow flex items-center justify-center">
          <AcademyTab />
          <MarketplaceTab />

          <div
            onClick={() => {
              window.open(
                "https://marketplace.dogami.com/collection/dogami",
                "_blank"
              );
            }}
            className="header-nav-button mr-10 cursor-pointer font-bonnbf text-xxs uppercase tracking-header text-white hover:text-pink"
          >
            <p>my collection</p>
          </div>

          <div
            onClick={() => {
              window.open(
                "https://marketplace.dogami.com/pet-sitting/shared-rewards",
                "_blank"
              );
            }}
            className="header-nav-button mr-10 cursor-pointer font-bonnbf text-xxs uppercase tracking-header text-white hover:text-pink"
          >
            <p>pet-sitting</p>
          </div>

          <DogaTab />

          <MoreTab />
        </section>
        <div className="flex cursor-pointer items-center justify-center gap-8">
          <div
            onClick={() => {
              window.open(
                "https://marketplace.dogami.com/doga-token",
                "_blank"
              );
            }}
            className="buy-star relative flex items-center justify-center font-bonnbf text-xxs uppercase tracking-medium text-white hover:text-blue"
          >
            <p>get $DOGA</p>
          </div>

          <div
            onClick={() => {
              window.open("https://marketplace.dogami.com/shop", "_blank");
            }}
            className="buy-star relative flex items-center justify-center font-bonnbf text-xxs uppercase tracking-medium text-white hover:text-blue"
          >
            <p>shop</p>
          </div>
        </div>
      </div>
    </>
  );
}
