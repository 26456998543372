import { useState } from "react";

import { ChevronIcon } from "../Icons/ChevronIcon";

import { ArrowIcon } from "../Icons/ArrowIcon";

import { QuestionIcon } from "../Icons/QuestionIcon";

import { AppleIcon } from "../Icons/AppleIcon";

import { TrophyIcon } from "../Icons/TrophyIcon";

import { GoogleIcon } from "../Icons/GoogleIcon";

export const AcademyTab = () => {
  const [academyHovered, setAcademyHovered] = useState<boolean>(false);

  return (
    <div
      onMouseOver={() => setAcademyHovered(true)}
      onMouseLeave={() => setAcademyHovered(false)}
      className="header-nav-button relative mr-10 flex cursor-pointer text-xxs text-white hover:text-pink"
    >
      <p className="font-bonnbf uppercase tracking-header">academy</p>

      {academyHovered ? (
        <ChevronIcon
          onClickIcon={() => {}}
          iconClass={"ml-2 fill-pink -rotate-90 w-2"}
        ></ChevronIcon>
      ) : (
        <ChevronIcon
          onClickIcon={() => {}}
          iconClass={"ml-2 fill-pink rotate-90 w-2"}
        ></ChevronIcon>
      )}

      {academyHovered && (
        <div className="absolute left-0 top-10 z-modal flex w-72 flex-col items-start justify-center gap-2 rounded-xl border border-white-10 bg-purple p-2 font-montserrat text-white">
          <div
            onClick={() =>
              window.open("https://dogami.com/game-deck", "_blank")
            }
            className="group flex w-full cursor-pointer items-center justify-between gap-2 rounded-md px-4 py-2 text-xs hover:bg-pink-10"
          >
            <div className="flex items-center justify-center gap-2 text-xs">
              <TrophyIcon
                iconClass={"group-hover:fill-pink fill-white w-4 mr-2"}
              ></TrophyIcon>
              Game Deck
            </div>
          </div>

          <div className="my-0.5 flex w-full items-center justify-start border-b border-white-10"></div>

          <a
            className="group flex w-full cursor-pointer items-center justify-between gap-2 rounded-md px-4 py-2 text-xs hover:bg-pink-10"
            href="https://apps.apple.com/app/id1670203326"
            target="_blank"
            rel="noopener noreferrer"
          >
            <div className="flex items-center justify-center gap-2 text-xs">
              <AppleIcon iconClass="group-hover:fill-pink fill-white w-4 mr-2"></AppleIcon>
              Download on iOS
            </div>
            <ArrowIcon iconClass="-rotate-45 mb-2 w-2 fill-white"></ArrowIcon>
          </a>
          <a
            className="group flex w-full cursor-pointer items-center justify-between gap-2 rounded-md px-4 py-2 text-xs hover:bg-pink-10"
            href="https://play.google.com/store/apps/details?id=io.dogami.game.academy"
            target="_blank"
            rel="noopener noreferrer"
          >
            <div className="flex items-center justify-center gap-2 text-xs">
              <GoogleIcon iconClass="group-hover:fill-pink fill-white w-4 mr-2"></GoogleIcon>
              Download on Android
            </div>
            <ArrowIcon iconClass="-rotate-45 mb-2 w-2 fill-white"></ArrowIcon>
          </a>
          <a
            className="group flex w-full cursor-pointer items-center justify-between gap-2 rounded-md px-4 py-2 text-xs hover:bg-pink-10"
            href="https://www.dogami.com/page/dogami-academy"
            target="_blank"
            rel="noopener noreferrer"
          >
            <div className="flex items-center justify-center gap-2 text-xs">
              <QuestionIcon iconClass="group-hover:fill-pink fill-white w-4 mr-2"></QuestionIcon>
              How to Play
            </div>
          </a>
        </div>
      )}
    </div>
  );
};
