export const GoogleIcon = ({ iconClass }: any) => {
  const computeIconClass = () => {
    return iconClass ? iconClass : "fill-white";
  };

  return (
    <svg
      className={`${computeIconClass()}`}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
    >
      <path
        fill="#4285F4"
        d="M23.525204,12.2759784 C23.525204,11.4602792 23.4590663,10.6401709 23.3179724,9.83769931 L11.9995957,9.83769931 L11.9995957,14.4585247 L18.4810969,14.4585247 C18.2121367,15.9488291 17.3479365,17.2671752 16.0825005,18.1049203 L16.0825005,21.1031658 L19.9493554,21.1031658 C22.2200854,19.0132123 23.525204,15.9267831 23.525204,12.2759784 Z"
      />
      <path
        fill="#34A853"
        d="M11.9995957,24 C15.2359371,24 17.9652223,22.9373865 19.9537646,21.1031658 L16.0869097,18.1049203 C15.0110687,18.8368449 13.6221756,19.2513083 12.0040049,19.2513083 C8.87348385,19.2513083 6.21915477,17.1393089 5.26677091,14.299794 L1.27645892,14.299794 L1.27645892,17.3906324 C3.31350217,21.442673 7.46254481,24 11.9995957,24 Z"
      />
      <path
        fill="#FBBC04"
        d="M5.26236173,14.299794 C4.75971469,12.8094897 4.75971469,11.1957281 5.26236173,9.70542377 L5.26236173,6.61458542 L1.27645892,6.61458542 C-0.425486307,10.0052483 -0.425486307,13.9999695 1.27645892,17.3906324 L5.26236173,14.299794 L5.26236173,14.299794 Z"
      />
      <path
        fill="#EA4335"
        d="M11.9995957,4.74950037 C13.7103593,4.72304526 15.3638035,5.3667862 16.6027843,6.54844766 L20.0287207,3.12251128 C17.8594019,1.08546803 14.9802044,-0.0344648374 11.9995957,0.000808638819 C7.46254481,0.000808638819 3.31350217,2.55813566 1.27645892,6.61458542 L5.26236173,9.70542377 C6.2103364,6.86149976 8.86907467,4.74950037 11.9995957,4.74950037 Z"
      />
    </svg>
  );
};
