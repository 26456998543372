export const TwitterIcon = ({ iconClass }: any) => {
  const computeIconClass = () => {
    return iconClass ? iconClass : "fill-white";
  };

  return (
    <svg
      className={`${computeIconClass()}`}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
    >
      <path
        fillRule="nonzero"
        d="M24,4.31429844 C23.1174139,4.7053845 22.1692945,4.96963184 21.1725535,5.08907163 C22.2010041,4.47368539 22.9704219,3.50513787 23.3372677,2.36415311 C22.3710385,2.93808423 21.3134854,3.3420726 20.2106932,3.55855106 C19.4691095,2.76672511 18.4868141,2.24188763 17.4163657,2.06552543 C16.3459174,1.88916324 15.2471417,2.07114861 14.2907778,2.58321767 C13.3343433,3.09528673 12.5737338,3.90879505 12.1269797,4.89744651 C11.680296,5.88609092 11.572483,6.99454155 11.8203118,8.05075577 C9.86241522,7.95245576 7.94708007,7.44355062 6.1986083,6.55715936 C4.45015767,5.67069059 2.90760856,4.42650843 1.67110015,2.90533165 C1.24830441,3.6346543 1.00519686,4.48024577 1.00519686,5.38080069 C1.00472474,6.19151151 1.20436889,6.98982033 1.58641416,7.70483839 C1.96845944,8.41985645 2.5210887,9.02959836 3.19527878,9.47980536 C2.41339558,9.45493087 1.64876949,9.24367394 0.965031269,8.86358057 L0.965031269,8.92699993 C0.964953757,10.0640386 1.35826654,11.1661262 2.0782313,12.0461755 C2.79820312,12.9262248 3.80048269,13.5301181 4.91500044,13.7553272 C4.18968026,13.9516454 3.4292328,13.9805364 2.69109486,13.8398864 C3.00554919,14.8182357 3.61806747,15.6737629 4.44291377,16.2867463 C5.26776006,16.8996591 6.26364837,17.2393755 7.29111248,17.2581899 C5.5469039,18.6274139 3.392816,19.3701251 1.17537215,19.3668837 C0.782573769,19.3670246 0.39010658,19.3440527 0,19.2981794 C2.25083062,20.7454092 4.87096626,21.5134176 7.5469039,21.5104666 C16.6053026,21.5104666 21.5572976,14.0079477 21.5572976,7.50112131 C21.5572976,7.28972344 21.5520127,7.07621159 21.5424998,6.86481373 C22.5056989,6.16825775 23.3371972,5.3056417 23.997886,4.31746941 L24,4.31429844 L24,4.31429844 Z"
      />
    </svg>
  );
};
