import { MouseEvent, ReactNode } from "react";
import closeIcon from "../../assets/icon/close-icon.svg";
import {
  CloseBtn,
  LayoutModalComponentWrapper,
  ModalComponentWrapper,
} from "./ModalComponent.styled";

type ModalProps = {
  display: boolean;
  children: ReactNode;
  closeModal: () => void;
  width?: string;
  height?: string;
  fullscreen?: boolean;
  overflowScroll?: boolean;
};

const fullscreenStyles = {
  content: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: "linear-gradient(0deg,#44004F  0%, #1C002E 90%, #1C002E 100% )",
    border: "none",
    overflow: "hidden",
    width: "100%",
  },
  overlay: {
    position: "fixed",
    border: "none",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: "linear-gradient(0deg,#44004F  0%, #1C002E 90%, #1C002E 100% )",
    zIndex: 9998,
  },
};
const regularStyles = {
  content: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "none",
    background: "transparent",
    overflow: "hidden",
  },
  overlay: {
    position: "fixed",
    border: "none",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: "transparent",
    zIndex: 9998,
  },
};
export const ModalComponent = ({
  children,
  closeModal,
  display,
  height,
  width,
  fullscreen,
  overflowScroll,
}: ModalProps) => {
  ModalComponentWrapper.setAppElement("#ModalElement");
  return (
    <>
      <div onClick={() => closeModal()}>
        <ModalComponentWrapper
          isOpen={display}
          onRequestClose={() => closeModal()}
          // @ts-ignore
          style={fullscreen ? fullscreenStyles : regularStyles}
        >
          <LayoutModalComponentWrapper
            height={height || "auto"}
            width={width || "auto"}
            fullscreen={fullscreen}
            overflowScroll={overflowScroll}
            onClick={(e: MouseEvent) => {
              e.stopPropagation();
            }}
          >
            <CloseBtn
              onClick={() => {
                closeModal();
              }}
              src={closeIcon}
              alt="close"
            />
            {children}
          </LayoutModalComponentWrapper>
        </ModalComponentWrapper>
      </div>
    </>
  );
};
