import styled from "styled-components";

export const DataSectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 5% 0;
  @media only screen and (max-width: 1024px) {
    height: 100%;
  }
  @media only screen and (max-width: 900px) {
    padding-bottom: 15%;
    br {
      display: none;
    }
  }
`;

export const DataWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  justify-content: space-between;
  text-align: center;
  width: 100%;
  gap: 2rem;
  div {
    display: flex;
    flex-direction: column;
  }
  @media only screen and (max-width: 900px) {
    grid-template-columns: 1fr 1fr;
  }
  @media only screen and (max-width: 428px) {
    grid-template-columns: 1fr;
    width: 50%;
    align-self: center;
  }
`;

export const DataNum = styled.div`
  font-size: 32px;
  font-weight: 400;
  line-height: 40px;
  color: #ff0060;
  @media only screen and (max-width: 428px) {
    font-size: 1.8rem;
    line-height: 2rem;
  }
`;

export const DataText = styled.div`
  text-transform: uppercase;
  font-size: 16px;
  line-height: 28px;
  @media only screen and (max-width: 428px) {
    font-size: 0.9rem;
    line-height: 1.3rem;
  }
`;

export const DataSubText = styled.div`
  text-transform: uppercase;
  font-size: 16px;

  @media only screen and (max-width: 428px) {
    font-size: 0.9rem;
    line-height: 1.3rem;
  }
`;
