import styled from "styled-components";

export const EarnSectionWrapper = styled.div`
  display: flex;
  min-height: 100vh;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 5%;
  @media only screen and (max-width: 1092px) {
    height: 100%;
    flex-direction: column;
    align-items: flex-start;
    gap: 3rem;
  }
`;

export const DogaTokenWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 24px;
  @media only screen and (max-width: 601px) {
    gap: 0.8rem;
  }
`;

export const BuyOrStake = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  .arrow-red {
    display: none;
  }

  p:hover {
    a {
      color: #ff0060;
    }

    .arrow-white {
      display: none;
    }

    .arrow-red {
      display: block;
      width: 14px;
    }
  }
`;

export const CardGrid = styled.div`
  max-width: 600px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;

  @media only screen and (max-width: 1092px) {
    align-self: center;
  }

  @media only screen and (min-width: 900px) and (max-width: 1092px) {
    margin-bottom: 24px;
  }
`;

export const Card = styled.div`
  width: 272px;
  height: 153px;
  position: relative;
  margin: 16px 0 0 16px;

  .cardBack {
    transform: rotateY(180deg);
  }
  .cardBack.flipped {
    transform: rotateY(0deg);
    background-color: #ffffff;
    div,
    ul,
    p {
      color: #000000;
    }
  }
  .cardFront {
    transform: rotateY(0deg);
  }
  .cardFront.flipped {
    transform: rotateY(-180deg);
  }

  @media only screen and (max-width: 900px) {
    height: 178px;
  }

  @media only screen and (max-width: 600px) {
    margin: 16px 0 0 0;
  }
`;

export const CardBackContainer = styled.div`
  width: 400px;
  height: 400px;
  display: flex;
  background: white;
  border-radius: 8px;
  margin: 32px;
  padding: 8px;

  @media only screen and (max-width: 900px) {
    width: 400px;
  }

  @media only screen and (max-width: 600px) {
    width: 328px;
  }
`;

export const CardBack = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  margin-left: 32px;
  ul {
    text-transform: none;
    padding: 0;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    font-family: "Montserrat", sans-serif;
    margin: 16px 0 24px 24px;
  }

  box-sizing: border-box;
  width: 320px;
  transition: transform 0.5s ease;
  position: absolute;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  @media only screen and (max-width: 600px) {
    width: 300px;
  }
`;

export const CardFront = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  padding: 24px 0 32px 32px;
  border-radius: 8px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  box-sizing: border-box;
  height: 100%;
  width: 270px;
  transition: transform 0.5s ease;
  position: absolute;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;

  background: rgba(34, 2, 52, 0.3);
  background-image: radial-gradient(
      circle at 1.2e2% 94%,
      rgba(255, 0, 96, 0.1) 0%,
      rgba(255, 0, 96, 0) 69%
    ),
    radial-gradient(
      circle at -9.9% -19%,
      rgba(20, 68, 255, 0.1) 0%,
      rgba(20, 68, 255, 0) 69%
    ),
    linear-gradient(
      -42deg,
      rgba(255, 0, 96, 0.06) 0%,
      rgba(17, 203, 237, 0.06) 100%
    );
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 8px;

  &:hover {
    background-image: none;
    background-color: #ffffff;

    div {
      color: black;
    }
  }

  @media only screen and (max-width: 900px) {
    width: 90%;
  }

  @media only screen and (max-width: 900px) {
    width: 100%;
  }
`;

export const LearnMoreBtn = styled.div`
  display: flex;
  flex-direction: row;
  gap: 2rem;
  img {
    width: 15px;
  }
`;

export const Number = styled.div`
  margin-top: 1rem;
  color: #ff0060 !important;
  font-size: 16px;
  font-weight: 500;
`;

export const Title = styled.div`
  color: #ffffff;
  font-size: 22px;
  font-weight: 400;
  line-height: 32px;
`;

export const ModalTitle = styled.div`
  color: black;
  font-size: 22px;
  font-weight: 400;
  line-height: 32px;
`;

export const StakeDogaLink = styled.div`
  font-family: "Montserrat", sans-serif;
  color: #ffffff;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  margin: 0;
  text-transform: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;

  a {
    text-decoration: none;
    color: black;
    border-bottom: 1px solid #ff0060;
    :hover {
      color: #ff0060;
    }
  }
  img {
    margin-left: 1rem;
    width: 12px;
  }
  :hover {
    img {
      animation: Arrow 0.4s infinite alternate ease-in-out;
    }
  }
  @keyframes Arrow {
    0% {
      -webkit-transform: translateX(0);
      opacity: 0.5;
    }
    100% {
      -webkit-transform: translateX(0.2rem);
      opacity: 0.9;
    }
  }
`;
