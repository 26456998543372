import logo from "../../assets/logo/logo_v2.png";

import { ArrowIcon } from "../04_EarnRewards/ArrowIcon";

import { DiscordIcon } from "../Icons/DiscordIcon";

import { InstagramIcon } from "../Icons/InstagramIcon";

import { LinkedinIcon } from "../Icons/LinkedinIcon";

import { MediumIcon } from "../Icons/MediumIcon";

import { TelegramIcon } from "../Icons/TelegramIcon";

import { TwitterIcon } from "../Icons/TwitterIcon";

export const Footer = () => {
  return (
    <main className="flex flex-col items-center justify-start bg-footer-background">
      <div className="mx-auto my-0 w-full max-w-xxxl px-6">
        <div className="mb-8 flex w-full items-start justify-between pt-6">
          <div className="flex flex-col items-start justify-start gap-8">
            <img src={logo} className="w-36" alt="logo" />

            <div className="flex items-start justify-start gap-8 laptop:hidden">
              <a
                className="w-fit flex h-8 cursor-pointer items-center justify-center rounded-xl bg-white-10 px-4 py-2 font-montserrat text-xxs font-semibold uppercase tracking-medium text-white backdrop-blur-lg backdrop-filter hover:bg-white-20"
                href="https://www.linkedin.com/company/dogami/"
                target="_blank"
                rel="noopener noreferrer"
              >
                careers
              </a>

              <a
                className="w-fit flex h-8 cursor-pointer justify-center rounded-xl bg-white px-4 py-2 font-montserrat text-xxs font-semibold uppercase tracking-medium text-purple hover:bg-purple-30 hover:text-white"
                href="https://whitepaper.dogami.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                whitepaper
                <ArrowIcon iconClass="w-2 mb-2 -rotate-45 ml-1 fill-pink"></ArrowIcon>
              </a>
            </div>

            <div className="flex items-start justify-start gap-6 laptop:gap-8">
              <a
                className="w-5 cursor-pointer opacity-60 hover:opacity-100"
                href="https://discord.com/invite/dogamiofficial"
                target="_blank"
                rel="noopener noreferrer"
              >
                <DiscordIcon iconClass="w-5 cursor-pointer fill-white-30 hover:fill-white"></DiscordIcon>
              </a>

              <a
                className="w-5 cursor-pointer opacity-60 hover:opacity-100"
                href="https://twitter.com/dogami"
                target="_blank"
                rel="noopener noreferrer"
              >
                <TwitterIcon iconClass="w-5 cursor-pointer fill-white-30 hover:fill-white"></TwitterIcon>
              </a>

              <a
                className="w-5 cursor-pointer opacity-60 hover:opacity-100"
                href="https://www.linkedin.com/company/dogami/mycompany/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <LinkedinIcon iconClass="w-5 cursor-pointer fill-white-30 hover:fill-white"></LinkedinIcon>
              </a>

              <a
                className="w-5 cursor-pointer opacity-60 hover:opacity-100"
                href="https://www.instagram.com/dogami/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <InstagramIcon iconClass="w-5 cursor-pointer fill-white-30 hover:fill-white"></InstagramIcon>
              </a>

              <a
                className="w-5 cursor-pointer opacity-60 hover:opacity-100"
                href="https://t.me/DogamiAnnouncement"
                target="_blank"
                rel="noopener noreferrer"
              >
                <TelegramIcon iconClass="w-5 cursor-pointer fill-white-30 hover:fill-white"></TelegramIcon>
              </a>

              <a
                className="w-5 cursor-pointer opacity-60 hover:opacity-100"
                href="https://dogami.medium.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <MediumIcon iconClass="w-5 cursor-pointer fill-white-30 hover:fill-white"></MediumIcon>
              </a>
            </div>
          </div>
          <div className="hidden items-start justify-start gap-4 laptop:flex">
            <a
              className="w-fit flex h-8 cursor-pointer items-center justify-center rounded-xl bg-white-10 px-4 py-2 font-montserrat text-xxs font-semibold uppercase tracking-medium text-white backdrop-blur-lg backdrop-filter hover:bg-white-20"
              href="https://www.linkedin.com/company/dogami/"
              target="_blank"
              rel="noopener noreferrer"
            >
              careers
            </a>

            <a
              className="w-fit flex h-8 cursor-pointer justify-center rounded-xl bg-white px-4 py-2 font-montserrat text-xxs font-semibold uppercase tracking-medium text-purple hover:bg-purple-30 hover:text-white"
              href="https://whitepaper.dogami.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              whitepaper
              <ArrowIcon iconClass="w-2 mb-2 -rotate-45 ml-1 fill-pink"></ArrowIcon>
            </a>
          </div>
        </div>

        <div className="mb-6 flex w-full flex-col items-start justify-between border-t border-white-10 pt-8 laptop:flex-row">
          <div className="font-montserrat text-xxs text-white-30">
            DOGAMI ©️ 2024 All Rights Reserved
          </div>

          <div className="mt-2 flex flex-wrap font-montserrat text-xxs laptop:m-0">
            <a
              href="https://termsandconditions.dogami.com/"
              className="cursor-pointer border-r border-white-30 pr-2 text-white hover:underline"
              target="_blank"
              rel="noopener noreferrer"
            >
              General&nbsp;Terms&nbsp;Of&nbsp;Use{" "}
            </a>

            <a
              className="cursor-pointer border-r  border-white-30 px-2 text-white hover:underline"
              href="https://termsandconditions.dogami.com/terms-and-conditions/section-9"
              target="_blank"
              rel="noopener noreferrer"
            >
              Staking Terms Of Use
            </a>

            <a
              className="cursor-pointer border-r  border-white-30 px-2 text-white hover:underline"
              href="https://termsandconditions.dogami.com/privacy-policy/privacy-policy-of-dogami"
              target="_blank"
              rel="noopener noreferrer"
            >
              Privacy Policy
            </a>

            <a
              className="cursor-pointer border-r border-white-30 px-2 text-white hover:underline"
              href="https://termsandconditions.dogami.com/cookie-policy/cookie-policy"
              target="_blank"
              rel="noopener noreferrer"
            >
              Cookie Policy
            </a>
            <a
              className="cursor-pointer pl-2 text-white hover:underline"
              href="https://whitepaper.dogami.com/community-and-socials/official-links#usddoga-listings-on-cex-and-dex-exchanges-1"
              target="_blank"
              rel="noopener noreferrer"
            >
              Smart Contracts
            </a>
          </div>
        </div>
      </div>
    </main>
  );
};
