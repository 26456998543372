import { useState } from "react";

import dogaToken from "../../assets/image/doga.png";

import { BridgeIcon } from "../Icons/BridgeIcon";

import { ChevronIcon } from "../Icons/ChevronIcon";

import { ArrowIcon } from "../Icons/ArrowIcon";

import { StakingIcon } from "../Icons/StakingIcon";

import { UserIcon } from "../Icons/UserIcon";

export const DogaTab = () => {
  const [moreHovered, setMoreHovered] = useState<boolean>(false);

  return (
    <div
      onMouseOver={() => setMoreHovered(true)}
      onMouseLeave={() => setMoreHovered(false)}
      className="header-nav-button relative mr-10 flex cursor-pointer text-xxs text-white hover:text-pink"
    >
      <p className="font-bonnbf uppercase tracking-header">$doga</p>

      {moreHovered ? (
        <ChevronIcon
          onClickIcon={() => {}}
          iconClass={"ml-2 fill-pink -rotate-90 w-2"}
        ></ChevronIcon>
      ) : (
        <ChevronIcon
          onClickIcon={() => {}}
          iconClass={"ml-2 fill-pink rotate-90 w-2"}
        ></ChevronIcon>
      )}

      {moreHovered && (
        <div className="absolute left-0 top-10 z-modal flex w-80 flex-col items-start justify-center gap-2 rounded-xl border border-white-10 bg-purple p-2 font-montserrat text-white">
          <a
            className="group flex w-full cursor-pointer items-center justify-between gap-2 rounded-md px-4 py-2 text-xs hover:bg-pink-10"
            href="https://marketplace.dogami.com/doga-token"
            target="_blank"
            rel="noopener noreferrer"
          >
            <div className="flex items-center justify-center gap-2 text-xs">
              <img className="mr-1 w-5" alt="doga token" src={dogaToken} />
              Learn about $DOGA
            </div>
            <ArrowIcon iconClass="-rotate-45 mb-2 w-2 fill-white"></ArrowIcon>
          </a>

          <a
            className="group flex w-full cursor-pointer items-center justify-between gap-2 rounded-md px-4 py-2 text-xs hover:bg-pink-10"
            href="https://staking.dogami.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            <div className="flex items-center justify-center gap-2 text-xs">
              <StakingIcon
                iconClass={"group-hover:fill-pink fill-white w-4 mr-2"}
              ></StakingIcon>
              Stake $DOGA
            </div>
            <ArrowIcon iconClass="-rotate-45 mb-2 w-2 fill-white"></ArrowIcon>
          </a>
          <a
            className="group flex w-full cursor-pointer items-center justify-between gap-2 rounded-md px-4 py-2 text-xs hover:bg-pink-10"
            href="https://bridge.dogami.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            <div className="flex items-center justify-center gap-2 text-xs">
              <BridgeIcon
                iconClass={"group-hover:fill-pink fill-white w-4 mr-2"}
              ></BridgeIcon>
              Bridge $DOGA
            </div>
            <ArrowIcon iconClass="-rotate-45 mb-2 w-2 fill-white"></ArrowIcon>
          </a>

          <a
            className="group flex w-full cursor-pointer items-center justify-between gap-2 rounded-md px-4 py-2 text-xs hover:bg-pink-10"
            href="https://snapshot.org/#/dogami-allies.eth"
            target="_blank"
            rel="noopener noreferrer"
          >
            <div className="flex items-center justify-center gap-2 text-xs">
              <UserIcon
                iconClass={"group-hover:fill-pink fill-white w-4 mr-2"}
              ></UserIcon>
              DOGAMI A.L.L.I.E.S (DAO){" "}
            </div>
            <ArrowIcon iconClass="-rotate-45 mb-2 w-2 fill-white"></ArrowIcon>
          </a>
        </div>
      )}
    </div>
  );
};
