import { useState } from "react";

import ReactPlayer from "react-player";

import ring from "../../assets/bg/hero-ring.webp";

import playButton from "../../assets/icon/play.svg";

import Phone1 from "../../assets/SmartphoneApple.png";

import Phone2 from "../../assets/SmartphoneGoogle.png";

import ArrowRight from "../../assets/icon/arrowRight-Pink.svg";

import {
  H2,
  H3,
  ResponsiveContainer,
  SectionWrapper,
  TextAnimation,
  TextArea,
} from "../../page/LandingPage.styled";

import { VideoWrapper } from "../05_Trailor/TrailorSection.styled";
import {
  AdoptButtonWrapper,
  BtnDiv,
  ButtonText,
} from "../AdoptButton/AdoptButton.styled";

import { ModalComponent } from "../ModalComponent/ModalComponent";

import {
  BottomTrailerContainer,
  PhoneArea,
  PurpleButtonShadow,
  RaiseSectionWrapper,
  RingWrapperMobile,
  TrailerContainer,
} from "./RaiseSection.styled";

export const RaiseSection = () => {
  const [openVideoModal, setOpenVideoModal] = useState<boolean>(false);

  function toggleFullScreen() {
    const video = document.querySelector("video") as HTMLVideoElement;
    if (!document.fullscreenElement) {
      video.requestFullscreen();
    } else if (document.fullscreenElement) {
      document.exitFullscreen();
    }
  }

  const closeModal = () => {
    setOpenVideoModal(false);
  };

  return (
    <>
      <SectionWrapper>
        <ResponsiveContainer>
          <RaiseSectionWrapper>
            <BottomTrailerContainer onClick={() => setOpenVideoModal(true)}>
              <img className="mobile-play-button" src={playButton} alt="play" />
              <PurpleButtonShadow></PurpleButtonShadow>
              <RingWrapperMobile>
                <img className="ring-mobile" src={ring} alt="" />
              </RingWrapperMobile>
              <a> WATCH TRAILER</a>
            </BottomTrailerContainer>
            <PhoneArea>
              <img src={Phone2} alt="phone-image" />
              <img src={Phone1} alt="phone-image" />
            </PhoneArea>
            <TextArea>
              <TextAnimation>
                <H2>CHASE THE </H2>
                <H2>GLORY</H2>
              </TextAnimation>
              <H3>
                STRATEGY MEETS ADRENALINE: <br /> DISCOVER DOGAMÍ ACADEMY
              </H3>

              <TrailerContainer onClick={() => setOpenVideoModal(true)}>
                <p onClick={() => setOpenVideoModal(true)}> WATCH TRAILER</p>
              </TrailerContainer>

              <div className="ml-8 mt-8 hidden w-72 items-start justify-start laptop:flex">
                <AdoptButtonWrapper>
                  <a
                    href={"https://www.dogami.com/page/dogami-academy"}
                    target="_parent"
                  >
                    <BtnDiv>
                      <ButtonText>
                        how to play <img src={ArrowRight} alt="white-arrow" />
                      </ButtonText>
                      <div className="mask-light">
                        <div className="cta-light-blue" />
                        <div className="cta-light-pink" />
                      </div>
                    </BtnDiv>
                  </a>
                </AdoptButtonWrapper>
              </div>
            </TextArea>
          </RaiseSectionWrapper>
        </ResponsiveContainer>
      </SectionWrapper>

      <ModalComponent
        width={"800px"}
        height={"auto"}
        closeModal={closeModal}
        display={openVideoModal}
      >
        <VideoWrapper>
          <ReactPlayer
            className="player"
            url="https://s3.amazonaws.com/statics.dogami.com/landing/Alt-Studio_Dogami_2202_Trailer_169.mp4"
            width="100%"
            height="100%"
            playing
            controls
            autohide
            onStart={toggleFullScreen}
          />
        </VideoWrapper>
      </ModalComponent>
    </>
  );
};
