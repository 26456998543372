export const BookIcon = ({ iconClass }: any) => {
  return (
    <svg
      className={iconClass}
      viewBox="0 0 26 30"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g stroke="none" strokeWidth="1" fillRule="evenodd">
        <g fillRule="nonzero">
          <path
            d="M26,22 L26,2 C25.998738,0.895953647 25.1040464,0.00126196688 24,0 L4,0 C1.79183657,0.00235344802 0.00235344802,1.79183657 0,4 L0,26 C0.00235344802,28.2081634 1.79183657,29.9976466 4,30 L25,30 C25.5522847,30 26,29.5522847 26,29 C26,28.4477153 25.5522847,28 25,28 L24,28 L24,24 C25.1040464,23.998738 25.998738,23.1040464 26,22 Z M24,2 L24,22 L7,22 L7,2 L24,2 Z M2,4 C2.00126197,2.89595365 2.89595365,2.00126197 4,2 L5,2 L5,22 L4,22 C3.29756376,21.9990057 2.60743216,22.184344 2,22.53711 L2,4 Z M22,28 L4,28 C2.8954305,28 2,27.1045695 2,26 C2,24.8954305 2.8954305,24 4,24 L22,24 L22,28 Z"
            id="Shape"
          ></path>
        </g>
      </g>
    </svg>
  );
};
