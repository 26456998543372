import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL || "https://proxy.dogami.com";
const API_PROXY_URL = "https://proxy.dogami.com";
const instance = axios.create({
  baseURL: API_URL,
  headers: { Accept: "application/json", "Content-Type": "application/json" },
});

// Interceptors

instance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    return Promise.reject(error);
  }
);
// end interceptors

export const getBannerInfo = async () => {
  const config = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };

  try {
    return await instance.get(
      `${API_URL}/api/landing-page-feature-flag/`,
      config
    );
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getFeatureFlags = async () => {
  const config = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };

  try {
    return await instance.get(`${API_URL}/api/feature_flags/`, config);
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getMediumXML = async () => {
  const config = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };

  try {
    return await instance.get(`${API_PROXY_URL}/medium/feed`, config);
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const registerToNewsletter = async (email: string) => {
  const config = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };
  // Update with env var
  return await instance.post(
    `${API_PROXY_URL}/mail/newsletter`,
    { email },
    config
  );
};
