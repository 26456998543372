import React from "react";

export const PrimarySmallWhiteButton = ({
  children,
  disabled,
  icon,
  onClick,
}: any) => {
  return (
    <div
      onClick={disabled ? () => {} : () => onClick()}
      className="w-fit relative flex cursor-pointer items-center justify-center font-montserrat"
    >
      <button
        style={{
          backgroundColor: disabled ? "rgba(255,255,255,0.2)" : "",
          transform: disabled ? "none" : "",
          opacity: disabled ? "0.6" : "",
          cursor: disabled ? "default" : "pointer",
        }}
        className="w-fit flex h-8 transform cursor-pointer justify-center rounded-xl bg-white px-6 py-2 text-white transition duration-200 hover:scale-105"
      >
        <span
          style={{
            color: disabled ? "transparent" : "",
            cursor: disabled ? "default" : "pointer",
          }}
          className="pointer-events-none cursor-pointer font-montserrat text-xxs font-semibold uppercase tracking-medium text-white"
        >
          {children}
        </span>
      </button>

      <div
        style={{
          opacity: disabled ? "0.6" : "",
        }}
        className="pointer-events-none absolute flex cursor-pointer items-center justify-center"
      >
        <span
          style={{
            color: disabled ? "rgba(255,255,255)" : "",
            cursor: disabled ? "default" : "pointer",
          }}
          className="pointer-events-none cursor-pointer font-montserrat text-xxs font-semibold uppercase tracking-medium text-purple"
        >
          {children}
        </span>
        {icon}
      </div>
    </div>
  );
};
