import {
  NoBackgroundDataSectionWrapper,
  ResponsiveContainer,
} from "../../page/LandingPage.styled";
import {
  DataNum,
  DataSectionWrapper,
  DataSubText,
  DataText,
  DataWrapper,
} from "./DataSection.styled";

export const Data = [
  {
    number: "25",
    text: "Full-time",
    subtext: "employees",
  },
  {
    number: "+200K",
    text: "Followers on",
    subtext: "social networks",
  },
  {
    number: "+50K",
    text: "Discord messages",
    subtext: "per month",
  },
  {
    number: "24K",
    text: "NFTS",
    subtext: "SOLD OUT",
  },
];

export const DataSection = () => {
  return (
    <>
      <NoBackgroundDataSectionWrapper>
        <ResponsiveContainer>
          <DataSectionWrapper>
            <DataWrapper>
              {Data.map((item, i) => (
                <div key={i}>
                  <DataNum>{item.number}</DataNum>
                  <DataText>{item.text}</DataText>
                  <DataSubText>{item.subtext}</DataSubText>
                </div>
              ))}
            </DataWrapper>
          </DataSectionWrapper>
        </ResponsiveContainer>
      </NoBackgroundDataSectionWrapper>
    </>
  );
};
