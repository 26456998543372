export const CloseIcon = ({ iconClass }: any) => {
  const computeIconClass = () => {
    return iconClass ? iconClass : "fill-white";
  };

  return (
    <svg
      className={`${computeIconClass()}`}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
    >
      <path
        fillRule="nonzero"
        d="M3.375211,21.5122 C3.884504,22.0098 4.74348,22.0034 5.2284,21.5185 L12.43732,14.3096 L19.642,21.5164 C20.1354,22.0098 20.9934,22.0173 21.4931,21.5101 C21.9928,21.0008 21.9949,20.162 21.5015,19.6665 L14.2968,12.45008 L21.5015,5.24538 C21.9949,4.75203 22.0024,3.903596 21.4931,3.403913 C20.9838,2.89462 20.1354,2.892511 19.642,3.395476 L12.43732,10.60018 L5.2284,3.393367 C4.74348,2.900948 3.874894,2.882901 3.375211,3.401804 C2.877637,3.911097 2.883965,4.75835 3.368883,5.24327 L10.5778,12.45008 L3.368883,19.6707 C2.883965,20.1535 2.868027,21.0125 3.375211,21.5122 Z"
      />
    </svg>
  );
};
