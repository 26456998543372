import ConnectButton from "./ConnectButton";

import { SignUpButton } from "./SignUpButton";
import { UserIcon } from "./UserIcon";

export default function HeaderConnectWalletSection() {
  return (
    <>
      <div className="hidden items-center justify-center gap-4 font-montserrat laptop:flex">
        <SignUpButton></SignUpButton>
        <ConnectButton></ConnectButton>
      </div>

      <div
        onClick={() => window.open("https://marketplace.dogami.com", "_blank")}
        className="flex items-center justify-center laptop:hidden"
      >
        <UserIcon iconClass={"cursor-pointer fill-white w-5"}></UserIcon>
      </div>
    </>
  );
}
