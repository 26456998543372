import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1800px;
  height: 100%;
  margin: 96px 24px 32px 24px;

  @media only screen and (max-width: 900px) {
    flex-direction: column;
    margin: 0 24px;
  }
`;

export const LeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  width: 20%;
  height: 100%;

  @media only screen and (max-width: 900px) {
    width: 100%;
    padding: 24px;
  }
`;

export const RightContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 85%;
  height: 100%;
  padding: 36px;

  .carousel {
    width: 100%;
  }

  .my-button-back {
    position: relative;
    z-index: 2 !important;
    border: 1px solid rgb(151, 151, 151);
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 50%;
    transform: rotate(180deg);
    width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 580px;
    margin-top: 16px;

    @media only screen and (max-width: 1100px) {
      margin-left: 380px;
    }

    &:hover {
      border: 2px solid rgb(255, 255, 255);
    }

    @media only screen and (max-width: 900px) {
      margin-top: -100px;
      margin-left: 180px;
    }

    @media only screen and (max-width: 600px) {
      margin-top: 8px;
    }
  }

  .my-button-forward {
    position: relative;
    z-index: 2 !important;
    border: 1px solid rgb(151, 151, 151);
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 50%;
    width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    margin-left: 620px;
    margin-top: -32px;
    align-items: center;

    @media only screen and (max-width: 1100px) {
      margin-left: 420px;
    }

    @media only screen and (max-width: 900px) {
      margin-left: 220px;
    }

    &:hover {
      border: 2px solid rgb(255, 255, 255);
    }
  }

  @media only screen and (max-width: 900px) {
    width: 100%;
  }
`;

export const Article = styled.div`
  border-radius: 8px;
  background-color: rgba(27, 2, 42, 1);
  width: 272px;
  height: 337px;
  cursor: pointer;
  border: 1px solid rgba(255, 255, 255, 0.1);

  img {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    width: 272px;
    height: 157px;
  }
`;

export const SubContainer = styled.div`
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 170px;
  padding: 16px;
`;

export const Button = styled.div`
  margin-top: 6px;
  border-radius: 12px;
  background-color: rgba(255, 255, 255, 0.1);
  align-self: end;
  color: white;
  padding: 8px 12px;
  margin-top: auto;
  cursor: pointer;
  font-family: "Montserrat";
  font-size: 12px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: 1px;
  text-align: center;
  line-height: 16px;
  text-transform: uppercase;

  &:hover {
    background-color: rgba(255, 255, 255, 0.4);
  }
`;

export const Title = styled.div`
  color: rgba(255, 255, 255, 1);
  font-family: "Montserrat";
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  letter-spacing: 0px;
  text-align: left;
  line-height: 24px;
  text-transform: uppercase;
  justify-self: flex-start;
  align-self: flex-start;
`;

export const ArticleDate = styled.div`
  color: rgba(170, 155, 176, 1);
  font-family: "Montserrat";
  font-size: 12px;
  font-weight: 500;
  font-style: normal;
  letter-spacing: 0px;
  text-align: left;
  line-height: 16px;
  justify-self: flex-start;
  align-self: flex-start;
  text-transform: capitalize;
`;

export const NewsSectionTitle = styled.div`
  color: rgba(255, 255, 255, 1);
  font-family: "BonnBF-Regular";
  font-size: 32px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: 0px;
  text-align: left;
  line-height: 40px;
  text-transform: uppercase;
`;

export const NewsSectionContent = styled.div`
  color: rgba(17, 203, 237, 1);
  font-family: "Montserrat";
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  letter-spacing: 0px;
  text-align: left;
  line-height: 24px;
  text-transform: uppercase;

  @media only screen and (max-width: 900px) {
    width: 300px;
  }
`;

export const NewsSectionLink = styled.div`
  cursor: pointer;
  margin-top: 16px;
  color: rgba(255, 255, 255, 1);
  font-family: "Montserrat";
  font-size: 12px;
  font-weight: 700;
  letter-spacing: 1px;
  text-align: center;
  line-height: 16px;
  display: flex;
  justify-content: start;
  align-items: center;
  text-transform: uppercase;

  .medium-arrow {
    margin-left: 8px;
  }

  &:hover {
    color: rgba(255, 0, 96, 1);
  }
`;

export const Border = styled.div`
  box-shadow: 0px 0px 8px rgba(255, 0, 96, 1);

  height: 1px;
  width: 175px;
  background-color: rgba(255, 0, 96, 1);
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;
