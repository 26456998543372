import styled from "styled-components";
import Background from "../assets/bg/Background-light@2x.jpg";

export const LandingPageBg = styled.div`
  width: 100%;
  background-image: url(${Background});
  background-size: 100% 100%;
  background-position: bottom center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow-y: scroll;
  overflow-x: hidden;
  scrollbar-width: none !important;
  ::-webkit-scrollbar {
    display: none !important;
  }
  -ms-overflow-style: none;

  @media only screen and (max-width: 900px) {
    background: none;
    height: 100%;
  }
`;

export const SectionWrapper = styled.div`
  height: fit-content !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
  @media only screen and (max-width: 900px) {
    padding: 10% 0;
    background: linear-gradient(180deg, #220234 0%, #2f0341 75%, #370449 100%);
  }
`;

export const NoBackgroundDataSectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
  @media only screen and (max-width: 900px) {
    padding: 10% 0;
  }
`;

export const SectionWrapper2 = styled.div`
  background-color: #220234;
  // height: fit-content !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
  @media only screen and (max-width: 900px) {
    padding: 10% 0;
  }
`;

export const ResponsiveContainer = styled.div`
  max-width: 1184px;
  margin: 0 auto;
  width: 100%;
`;

export const InvestorSectionWrapper = styled.div`
  background-color: #220234;
  height: fit-content !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;

  h2 {
    font-size: 2rem;
    line-height: 2.5rem;
  }
`;

export const TextArea = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 400px;
  width: 40%;
  @media only screen and (max-width: 900px) {
    gap: 8px;
    width: 100%;
    h3,
    p {
      width: 90%;
    }
  }
  @media only screen and (max-width: 601px) {
    gap: 0;
    min-width: 0;
    text-align: left;
    br {
      display: none !important;
    }
  }
`;

export const H1 = styled.h1`
  font-size: 64px;
  font-weight: 400;
  text-align: left;
  line-height: 25px;
  @media only screen and (max-width: 1250px) {
    font-size: 3rem;
  }
  @media only screen and (max-width: 601px) {
    font-size: 3rem;
  }
  @media only screen and (max-width: 428px) {
    font-size: 2.6rem;
    line-height: 1.2rem;
  }
  @media only screen and (max-width: 390px) {
    font-size: 2.3rem;
    line-height: 1rem;
  }
  @media only screen and (max-width: 320px) {
    font-size: 2rem;
    line-height: 0.8rem;
  }
`;

export const H2 = styled.h2`
  font-weight: 500;
  font-size: 48px;
  line-height: 56px;
  margin: 0;
  @media only screen and (max-width: 900px) {
    width: 100%;
    font-size: 3rem;
    line-height: 3.6rem;
  }
  @media only screen and (max-width: 428px) {
    font-size: 2.8rem;
    line-height: 3.5rem;
  }
  @media only screen and (max-width: 390px) {
    font-size: 2rem;
    line-height: 40px;
  }
  @media only screen and (max-width: 320px) {
    font-size: 1.8rem;
  }
`;

export const H3 = styled.h3`
  font-family: "Montserrat", sans-serif;
  color: #11cbed;
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  margin: 0;

  @media only screen and (max-width: 601px) {
    font-size: 1.2rem;
  }
  @media only screen and (max-width: 428px) {
    font-size: 1rem;
  }
  @media only screen and (max-width: 320px) {
    font-size: 0.8rem;
  }
`;

export const Pwhite = styled.p`
  font-family: "Montserrat", sans-serif;
  color: #ffffff;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  margin: 0;
  text-transform: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  :hover {
    img {
      animation: Arrow 0.4s infinite alternate ease-in-out;
    }
  }
  @keyframes Arrow {
    0% {
      -webkit-transform: translateX(0);
      opacity: 0.5;
    }
    100% {
      -webkit-transform: translateX(0.2rem);
      opacity: 0.9;
    }
  }
  u {
    padding-bottom: 4px;
    text-decoration: none;
    border-bottom: 1px solid #ff0060;
    :hover {
      color: #ff0060;
    }
  }
  img {
    margin-left: 1rem;
  }
  @media only screen and (max-width: 900px) {
    font-size: 20px;
    line-height: 32px;
    br {
      display: none;
    }
    max-width: 100%;
  }
  @media only screen and (max-width: 601px) {
    font-size: 1rem;
    line-height: 1.6rem;
  }
  @media only screen and (max-width: 320px) {
    font-size: 0.8rem;
    line-height: 1.2rem;
    width: 270px;
  }
`;

export const PwhiteSmall = styled.p`
  color: #ffffffb3;
  font-weight: 500;
  font-size: 14px;
  line-height: 26px;
  margin: 0;
  text-transform: none;
  a {
    text-decoration: none;
  }
  a:link,
  a:visited,
  a:hover {
    color: #ffffff !important;
  }
  @media only screen and (max-width: 428px) {
    font-size: 1rem;
    line-height: 1.8rem;
  }
  @media only screen and (max-width: 320px) {
    font-size: 0.8rem;
    line-height: 1.5rem;
  }
`;

export const RedLabel = styled.div<{
  width: string;
  background: boolean;
}>`
  width: ${(props) => props.width} !important;
  height: 28px;
  background: ${(props) => (props.background ? "#ff0060" : "#220234")};
  box-shadow: ${(props) =>
    props.background ? "0px 0px 8px #ff0060" : "0px 0px 8px #220234"};
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  font-weight: bold;
  color: #ffffff;
  @media only screen and (max-width: 428px) {
    font-size: 0.6rem;
    height: 24px;
  }
`;

export const HR = styled.div`
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  width: 100%;
  align-self: center;
  padding-bottom: 0;
`;

export const ModalDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 20%;
  min-width: 300px;
`;

export const TextAnimation = styled.div`
  h2 {
    animation: fadeInBottom2 0.5s cubic-bezier(0.65, 0, 0.35, 1) both;
  }
  h2:first-child {
    animation-delay: 0s;
  }
  h2:nth-child(2) {
    animation-delay: 0.5s;
  }
  @keyframes fadeInBottom2 {
    from {
      opacity: 0;
      transform: translateY(30px);
    }
    to {
      transform: translateY(0);
      opacity: 1;
    }
  }
`;

export const OnePagerDesign = styled.div`
  cursor: pointer;
  justify-content: center;
  align-items: center;
  height: 304px;
  width: 100%;
  display: flex;
  background-color: #220234;
  border-radius: 16px;
  border: 1px solid;
  border-image-slice: 1;
  border-image-source: linear-gradient(
    to left,
    rgba(255, 255, 255, 0) 0%,
    rgba(10, 192, 242, 0.3),
    rgba(78, 76, 191, 0.3),
    rgba(255, 255, 255, 0) 100%
  );
  margin: 0 5%;

  @media only screen and (max-width: 980px) {
    display: none;
  }
`;

export const OnePagerCard = styled.div`
  height: 100%;
  min-height: 278px;
  width: 100%;
  border-top-left-radius: 16px;
  border-bottom-left-radius: 16px;
  min-width: 360px;

  @media only screen and (max-width: 980px) {
    display: none;
  }
`;

export const OnePagerText = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1rem;
  padding: 0 3rem 0 16rem;
  align-items: start;

  @media only screen and (max-width: 1200px) {
    padding: 2rem 1rem 2rem 15rem;
  }

  @media only screen and (max-width: 980px) {
    padding: 2rem;
  }
`;

export const DogaTitle = styled.div`
  opacity: 1;
  color: rgba(255, 255, 255, 1);
  font-family: "BonnBF-Regular";
  font-size: 32px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: 0px;
  text-align: left;
  line-height: 40px;
  text-transform: uppercase;
`;

export const DogaText = styled.div`
  color: #aa9bb0;
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: 0px;
  text-align: left;
  line-height: 24px;
  text-transform: none;
`;

export const DiscoverBtnDiv = styled.div`
  width: 167px;
  height: 32px;
  margin-top: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  border-radius: 12px;
  background: #fff;
  color: rgba(27, 2, 42, 1);
  font-family: "Montserrat";
  font-size: 12px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: 1px;
  line-height: 16px;
  text-transform: uppercase;
  cursor: pointer;

  &:hover {
    background: rgba(255, 255, 255, 0.6);
  }

  img {
    width: 12px;
    margin-left: 0.5rem;
  }

  @media only screen and (max-width: 1200px) {
    margin-top: 0;
  }
`;

export const OnePagerDesignMobile = styled.div`
  cursor: pointer;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 600px;
  height: 570px;
  background-color: #220234;
  margin: 64px 24px 0 24px;
  border-radius: 16px;
  border: 1px solid;
  border-image-slice: 1;
  border-image-source: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0) 0%,
    rgba(10, 192, 242, 0.3),
    rgba(78, 76, 191, 0.3),
    rgba(255, 255, 255, 0) 100%
  );

  @media only screen and (min-width: 981px) {
    display: none;
  }
`;

export const OnePagerTextMobile = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1rem;
  padding: 18rem 24px 0 24px;
  align-items: start;

  @media only screen and (max-width: 600px) {
    padding-top: 11rem;
  }

  @media only screen and (max-width: 500px) {
    padding-top: 5rem;
  }

  @media only screen and (max-width: 400px) {
    padding-top: 2rem;
  }
`;

export const DogaTitleMobile = styled.div`
  opacity: 1;
  color: rgba(255, 255, 255, 1);
  font-family: "BonnBF-Regular";
  font-size: 24px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: 0px;
  text-align: left;
  line-height: 32px;
  text-transform: uppercase;
  margin-top: 1rem;
`;
