import styled from "styled-components";

export const FeaturedSectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  padding: 16px 0 64px 0;
  gap: 2rem;

  @media only screen and (max-width: 900px) {
    padding: 0;
  }
`;

export const LogoImgArea = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  align-self: center;
  margin: 0 32px;

  @media only screen and (max-width: 1024px) {
    grid-template-columns: 1fr 1fr;
  }
`;

export const Logos = styled.div`
  border: 1px solid rgba(255, 255, 255, 0.1);
  padding: 32px 96px;
  display: grid;
  justify-items: center;

  img {
    aspect-ratio: 1/1;
    width: 100%;
    opacity: 1;
  }

  :nth-child(1) {
    border-top-left-radius: 8px;
  }

  :nth-child(4) {
    border-top-right-radius: 8px;
  }

  :nth-child(5) {
    border-bottom-left-radius: 8px;
  }

  :nth-child(8) {
    border-bottom-right-radius: 8px;
  }

  @media only screen and (max-width: 1200px) {
    padding: 32px 80px;
  }

  @media only screen and (max-width: 1024px) {
    padding: 32px 96px;

    :nth-child(1) {
      border-top-left-radius: 8px;
    }

    :nth-child(2) {
      border-top-right-radius: 8px;
    }

    :nth-child(4) {
      border-top-right-radius: 0px;
    }

    :nth-child(5) {
      border-bottom-left-radius: 0px;
    }

    :nth-child(7) {
      border-bottom-left-radius: 8px;
    }

    :nth-child(8) {
      border-bottom-right-radius: 8px;
    }
  }

  @media only screen and (max-width: 640px) {
    padding: 24px 76px;
  }

  @media only screen and (max-width: 500px) {
    padding: 24px 56px;
  }

  @media only screen and (max-width: 420px) {
    padding: 24px 32px;
  }
`;

export const FeatureInTitle = styled.div`
  color: #11cbed;
  margin-top: 32px;
  font-size: 24px;
`;
