import styled from "styled-components";

export const AdoptButtonWrapper = styled.div`
  width: 200px;
  height: 48px;
  overflow: hidden;
  border-radius: 16px;
  cursor: pointer;
  z-index: 9;
  a,
  a:visited,
  a:hover {
    color: #ffffff;
    text-decoration: none;
  }
`;

export const BtnDiv = styled.div`
  width: 200px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  border-radius: 16px;
  background: #fff;

  &:hover::after {
    content: "";
    display: block;
    width: 75px;
    height: 175%;
    border-radius: 16px;
    background: rgb(255, 255, 255);
    background: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 1) 25%,
      rgba(255, 255, 255, 1) 50%,
      rgba(255, 255, 255, 1) 75%,
      rgba(255, 255, 255, 0) 100%
    );
    opacity: 0.5;
    position: absolute;
    top: -20px;
    left: 0;
    animation: shine 300ms linear;
    transform: translateX(250px) rotate(-25deg);
  }
  @keyframes shine {
    0% {
      transform: translateX(-30px) rotate(-25deg);
    }

    100% {
      transform: translateX(250px) rotate(-25deg);
    }
  }
`;

export const ButtonText = styled.div`
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  text-transform: uppercase;
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #220234;

  img {
    width: 12px;
    margin-left: 1rem;
  }
`;
