import styled from "styled-components";
import Pod2Bg from "../../assets/pods/2/anim.svg";
import Pod3Bg from "../../assets/pods/3/anim.svg";

export const AdoptSectionWrapper = styled.div`
  display: flex;
  min-height: 100vh;
  height: 100vh;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 5%;
  @media only screen and (max-width: 900px) {
    min-height: 40vh;
    height: 100%;
    flex-direction: column;
    gap: 3rem;
  }
  @media only screen and (max-width: 601px) {
    gap: 1rem;
    h3 {
      width: 80%;
    }

    margin-bottom: 64px;
  }

  h3 {
    margin-bottom: 24px;

    @media only screen and (max-width: 600px) {
      margin-bottom: 16px;
    }
  }
`;

// Pods - desktop
export const PodsWrapper = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  @media only screen and (max-width: 900px) {
    position: relative;
    height: 650px;
  }
  @media only screen and (max-width: 601px) {
    display: flex;
    flex-direction: column;
  }
  @media only screen and (max-width: 601px) {
    height: 450px;
  }
  @media only screen and (max-width: 427px) {
    height: 350px;
  }
  @media only screen and (max-width: 360px) {
    height: 300px;
  }
`;

export const Pod2 = styled.object`
  position: absolute;
  top: 25%;
  left: 15%;
  background: url(${Pod2Bg});
  background-repeat: no-repeat;
  background-size: 100%, 100%;
  display: flex;
  justify-content: flex-start;
  width: 32%;
  img {
    width: 100%;
  }
  @media only screen and (min-width: 1440px) {
    width: 24%;
    max-width: 300px;
  }

  @media only screen and (min-width: 900px) and (max-width: 1440px) {
    width: 23%;
  }

  @media only screen and (min-width: 900px) and (max-width: 1200px) {
    width: 30%;
  }
  @media only screen and (max-width: 900px) {
    top: 10%;
    left: 10%;
    width: 26%;
  }
  @media only screen and (max-width: 600px) {
    width: 28%;
  }
  @media only screen and (max-width: 400px) {
    width: 32%;
  }
`;

export const Pod3 = styled.object`
  position: absolute;
  top: 5%;
  right: 10%;
  background: url(${Pod3Bg});
  background-repeat: no-repeat;
  background-size: 100%, 100%;
  display: flex;
  justify-content: flex-end;
  width: 32%;
  img {
    width: 100%;
  }
  @media only screen and (min-width: 1440px) {
    width: 24%;
    max-width: 300px;
  }
  @media only screen and (min-width: 900px) and (max-width: 1440px) {
    width: 23%;
  }
  @media only screen and (min-width: 900px) and (max-width: 1200px) {
    width: 30%;
  }
  @media only screen and (max-width: 900px) {
    top: 0%;
    right: 18%;
    width: 26%;
  }
  @media only screen and (max-width: 600px) {
    width: 28%;
  }
  @media only screen and (max-width: 400px) {
    width: 32%;
  }
`;

export const Pod4 = styled.object`
  position: absolute;
  top: 41%;
  right: 18%;
  margin: 16px 0 8px 0;
  background: url(${Pod3Bg});
  background-repeat: no-repeat;
  background-size: 100%, 100%;
  display: flex;
  justify-content: flex-end;
  width: 32%;
  img {
    width: 100%;
  }
  @media only screen and (min-width: 1440px) {
    width: 24%;
    max-width: 300px;
  }
  @media only screen and (min-width: 900px) and (max-width: 1440px) {
    width: 23%;
  }
  @media only screen and (min-width: 900px) and (max-width: 1200px) {
    width: 30%;
  }
  @media only screen and (max-width: 900px) {
    top: 34%;
    width: 26%;
    right: 23%;
  }
  @media only screen and (max-width: 600px) {
    width: 28%;
  }
  @media only screen and (max-width: 400px) {
    width: 32%;
  }
`;

export const Pod5 = styled.object`
  position: absolute;
  top: 65%;
  right: 63%;
  background: url(${Pod3Bg});
  background-repeat: no-repeat;
  background-size: 100%, 100%;
  display: flex;
  justify-content: flex-end;
  width: 32%;
  img {
    width: 100%;
  }
  @media only screen and (min-width: 1440px) {
    width: 24%;
    max-width: 300px;
  }
  @media only screen and (min-width: 900px) and (max-width: 1440px) {
    width: 23%;
  }
  @media only screen and (min-width: 900px) and (max-width: 1200px) {
    width: 30%;
  }
  @media only screen and (max-width: 900px) {
    top: 50%;
    right: 64%;
    width: 26%;
  }
  @media only screen and (max-width: 600px) {
    width: 28%;
  }
  @media only screen and (max-width: 400px) {
    width: 32%;
  }
`;

export const Pod1 = styled.object`
  position: absolute;
  top: 81%;
  right: 25%;
  margin: 24px 0 8px 0;
  background: url(${Pod3Bg});
  background-repeat: no-repeat;
  background-size: 100%, 100%;
  display: flex;
  justify-content: flex-end;
  width: 32%;
  img {
    width: 100%;
  }
  @media only screen and (min-width: 1440px) {
    width: 24%;
    max-width: 300px;
  }
  @media only screen and (min-width: 1200px) and (max-width: 1440px) {
    width: 21%;
  }
  @media only screen and (min-width: 900px) and (max-width: 1200px) {
    width: 30%;
  }
  @media only screen and (max-width: 900px) {
    top: 72%;
    right: 28%;
    width: 26%;
  }
  @media only screen and (max-width: 600px) {
    width: 28%;
  }
  @media only screen and (max-width: 400px) {
    width: 32%;
  }
`;

// Pods - mobile
export const PodsWrapperMobile = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  gap: 0;
  @media only screen and (min-width: 901px) {
    display: none;
  }
`;

export const SlideWrapper = styled.div`
  height: 100vh;
  width: fit-content;
  overflow: hidden;
  position: relative;
  @media only screen and (max-width: 900px) {
    height: fit-content;
    width: 100vw;
  }
`;

export const FirstGroup = styled.div`
  display: flex;
  flex-direction: row;
  width: calc(190px * 9);
  height: 100%;
  animation: dogScroll1 10s linear infinite;
  img {
    width: 190px;
  }
  @keyframes dogScroll1 {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(calc((-190px * 3)));
    }
  }
`;

export const SecondGroup = styled.div`
  display: flex;
  animation: dogScroll2 10s linear infinite;
  flex-direction: row;
  width: calc(190px * 9);
  height: 100%;
  img {
    width: 190px;
  }
  img:nth-child(even) {
    margin-right: 0;
  }
  img:nth-child(odd) {
    margin-left: 0;
  }
  @keyframes dogScroll2 {
    0% {
      transform: translateX(calc((-190px * 3)));
    }
    100% {
      transform: translateX(0);
    }
  }
`;

export const ArOverlayTop = styled.div`
  background: linear-gradient(0deg, #44105100 35%, #441051 95%);
  width: 100%;
  height: 40%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  @media only screen and (max-width: 900px) {
    display: none;
  }
`;

export const ArOverlayBottom = styled.div`
  background: linear-gradient(0deg, #441051 35%, #44105100 95%);
  width: 100%;
  height: 20%;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  @media only screen and (max-width: 900px) {
    display: none;
  }
`;
