import CoinMarketCap from "../../assets/featured/CoinMarketCap.svg";
import CoinTelegraph from "../../assets/featured/Cointelegraph.svg";
import Entrepreneur from "../../assets/featured/Entrepreneur.svg";
import Forbes from "../../assets/featured/Forbes.svg";
import LOfficiel from "../../assets/featured/LOfficiel.svg";
import Mashable from "../../assets/featured/Mashable.svg";
import Nasdaq from "../../assets/featured/Nasdaq.svg";
import Sifted from "../../assets/featured/Sifted.svg";

import {
  ResponsiveContainer,
  SectionWrapper2,
} from "../../page/LandingPage.styled";
import {
  FeaturedSectionWrapper,
  FeatureInTitle,
  LogoImgArea,
  Logos,
} from "./FeaturedSection.styled";

export const FeaturedSection = () => {
  return (
    <>
      <SectionWrapper2>
        <ResponsiveContainer>
          <FeaturedSectionWrapper>
            <FeatureInTitle>FEATURED IN</FeatureInTitle>
            <LogoImgArea>
              <Logos>
                <img src={CoinMarketCap} alt="coinmarketcap" />
              </Logos>
              <Logos>
                <img src={Forbes} alt="forbes" />
              </Logos>
              <Logos>
                <img src={CoinTelegraph} alt="coinTelegraph" />
              </Logos>
              <Logos>
                <img src={Mashable} alt="mashable" />
              </Logos>
              <Logos>
                <img src={Nasdaq} alt="nasdaq" />
              </Logos>
              <Logos>
                <img src={Entrepreneur} alt="entrepreneur" />
              </Logos>
              <Logos>
                <img src={LOfficiel} alt="lofficiel" />
              </Logos>
              <Logos>
                <img src={Sifted} alt="sifted" />
              </Logos>
            </LogoImgArea>
          </FeaturedSectionWrapper>
        </ResponsiveContainer>
      </SectionWrapper2>
    </>
  );
};
