import Modal from "react-modal";
import styled from "styled-components";

export const ModalComponentWrapper = styled(Modal)`
  background: rgba(50, 10, 81, 0.4);
  backdrop-filter: blur(20px);
  position: fixed;
  display: flex;
  width: 100%;
  height: 100vh;
  align-items: center;
  justify-content: center;
  top: 0;
  right: 0;
  z-index: 100;
  overflow: hidden;
`;

export const LayoutModalComponentWrapper = styled.div<{
  width: string;
  height: string;
  fullscreen?: boolean;
  overflowScroll?: boolean;
}>`
  overflow: ${(props) => (props.overflowScroll ? "scroll" : "hidden")};
  position: relative;
  width: ${(props) => props.width};
  height: ${(props) => props.height};

  border-radius: 24px;
  padding: 3rem 3rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  h1 {
    font-family: "Montserrat", sans-serif;
    font-weight: bold;
    font-size: 43px;
    line-height: 49px;
    color: #ffffff;
    margin-top: 0;
  }
  p {
    text-align: center;
    font-family: "Montserrat", sans-serif;
    font-size: 18px;
    line-height: 21px;
    color: #ffffff;
    margin-bottom: 2rem;
  }
  @media only screen and (max-width: 800px) {
    width: 100%;
    height: 100vh;
    padding: 1rem;
  }
`;

export const WrapperButton = styled.div`
  display: flex;
  align-items: center;
  text-align: center;
  display: flex;
  gap: 2rem;
`;

export const YesButton = styled.button`
  background: linear-gradient(93.55deg, #0ac0f2 0%, #4e4cbf 100%);
  border-radius: 8px;
  border: none;
  font-family: "Montserrat", sans-serif;
  font-weight: bold;
  font-size: 24px;
  line-height: 28px;
  display: flex;
  align-items: center;
  text-align: center;
  display: flex;
  text-transform: uppercase;
  padding: 1rem 3rem;
  color: #ffffff;
  width: 313px;
  height: 64px;
  justify-content: center;
  cursor: pointer;
`;

export const NoButton = styled.button`
  width: 313px;
  height: 64px;
  font-family: "Montserrat", sans-serif;
  font-weight: bold;
  font-size: 24px;
  line-height: 28px;
  display: flex;
  align-items: center;
  text-align: center;
  display: flex;
  text-transform: uppercase;
  padding: 1rem 3rem;
  color: #ffffff;
  border: 10px solid;
  border-image-slice: 1;
  border-width: 3px;
  border-image-source: linear-gradient(to left, #0ac0f2, #4e4cbf);
  background-color: transparent;
  cursor: pointer;
`;

export const CloseBtn = styled.img`
  position: absolute;
  z-index: 100;
  right: 2rem;
  top: 2rem;
  width: 35px;
  cursor: pointer;
`;
