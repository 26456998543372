import React from "react";

export const SecondarySmallWhite = ({ children, icon, onClick }: any) => {
  return (
    <div
      onClick={onClick}
      className="w-fit flex h-8 cursor-pointer items-center justify-center rounded-xl bg-white-10 px-4 py-1 text-xxs font-semibold uppercase tracking-medium text-white backdrop-blur-lg backdrop-filter hover:bg-white-20"
    >
      {children}
      {icon}
    </div>
  );
};
