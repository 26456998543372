import {
  SectionWrapper,
  TextArea,
  H2,
  H3,
  TextAnimation,
  ResponsiveContainer,
} from "../../page/LandingPage.styled";
import { AdoptButton } from "../AdoptButton/AdoptButton";
import { AdoptSectionWrapper } from "./AdoptSection.styled";
import Pods from "./Pods";

export const AdoptSection = () => {
  return (
    <>
      <SectionWrapper>
        <ResponsiveContainer>
          <AdoptSectionWrapper>
            <TextArea>
              <TextAnimation>
                <H2>ADOPT</H2>
                <H2>DOGAMÍ NFTS</H2>
              </TextAnimation>
              <H3>
                CHOOSE YOUR UNIQUE <br />
                TRUE VIRTUAL COMPANION
              </H3>
              <AdoptButton />
            </TextArea>
            <Pods />
          </AdoptSectionWrapper>
        </ResponsiveContainer>
      </SectionWrapper>
    </>
  );
};
