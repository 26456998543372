export const TelegramIcon = ({ iconClass }: any) => {
  const computeIconClass = () => {
    return iconClass ? iconClass : "fill-white";
  };

  return (
    <svg
      className={`${computeIconClass()}`}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
    >
      <path
        fillRule="nonzero"
        d="M23.9512111,2.41426611 C23.931936,2.32569842 23.8894859,2.24383574 23.8282108,2.17702559 C23.7669356,2.11022519 23.6890105,2.06087583 23.6024603,2.03401902 C23.2873096,1.97186639 22.9612839,1.99517269 22.6582082,2.10151917 C22.6582082,2.10151917 1.63040451,9.65931203 0.429648872,10.4963139 C0.170148302,10.6763143 0.0838981124,10.7805645 0.0411480185,10.9043148 C-0.166602438,11.4998161 0.479898982,11.7623166 0.479898982,11.7623166 L5.89941389,13.5285705 C5.99095159,13.5443956 6.0849193,13.5386956 6.17391449,13.5120705 C7.4069172,12.7335688 18.5781992,5.67903078 19.2239507,5.44203026 C19.3252009,5.41128019 19.4002011,5.44203026 19.383701,5.51702293 C19.1197004,6.42152491 9.47242924,14.9948238 9.41917912,15.0473239 C9.39330407,15.0685489 9.37290402,15.095699 9.35985399,15.126599 C9.34680396,15.1574241 9.34140395,15.1909492 9.34417896,15.2243243 L8.84017785,20.5125859 C8.84017785,20.5125859 8.62867739,22.1625895 10.275681,20.5125859 C11.4434336,19.3433333 12.563936,18.3758312 13.1256873,17.9055801 C14.9894414,19.191833 16.9949458,20.6145861 17.8596977,21.3555877 C18.004748,21.496513 18.1767984,21.6066883 18.3654238,21.6796634 C18.5541242,21.7525636 18.7554996,21.7867637 18.9577001,21.7800887 C19.7827019,21.7485886 20.0077024,20.8463366 20.0077024,20.8463366 C20.0077024,20.8463366 23.8387108,5.43003024 23.9677111,3.3637757 C23.9797111,3.16127525 23.9969612,3.03151747 23.9984612,2.89276716 C24.0052862,2.73183931 23.9893861,2.57075395 23.9512111,2.41426611 Z"
      />
    </svg>
  );
};
