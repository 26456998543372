import { useEffect } from "react";

import { H1, ResponsiveContainer } from "../../page/LandingPage.styled";

import {
  DesktopDisplay,
  MainSectionWrapper,
  MainTextArea,
  MobileDisplay,
} from "./FirstView.styled";

import downloadOnAppStore from "../../assets/logo/appstore-badge.png";

import downloadOnPlayStore from "../../assets/logo/google-play-badge.png";

import ReactGA from "react-ga4";

export const LandingMain = () => {
  useEffect(() => {
    ReactGA.event({
      category: "Landing Page",
      action: "Visit home page",
    });
  }, []);

  return (
    <>
      <MainSectionWrapper>
        <ResponsiveContainer>
          <MainTextArea>
            <DesktopDisplay>
              <div className="text-xl">STEP INTO THE</div>
              <div className="my-3 text-xl">DOGAMÍ ACADEMY</div>

              <div className="w-fit flex items-center justify-center gap-4">
                <img
                  onClick={() =>
                    window.open(
                      "https://apps.apple.com/app/id1670203326",
                      "_blank"
                    )
                  }
                  className="z-foreground h-12 transform cursor-pointer transition duration-200 hover:scale-105"
                  alt="downloadOnAppStore"
                  src={downloadOnAppStore}
                />
                <img
                  onClick={() =>
                    window.open(
                      "https://play.google.com/store/apps/details?id=io.dogami.game.academy",
                      "_blank"
                    )
                  }
                  className="z-foreground h-12 transform cursor-pointer transition duration-200 hover:scale-105"
                  alt="downloadOnPlayStore"
                  src={downloadOnPlayStore}
                />
              </div>
            </DesktopDisplay>

            <MobileDisplay>
              <H1>STEP INTO</H1>
              <H1>THE</H1>
              <H1>DOGAMÍ</H1>
              <H1>ACADEMY</H1>

              <div className="w-fit mt-8 flex flex-col items-center justify-center gap-4">
                <img
                  onClick={() =>
                    window.open(
                      "https://apps.apple.com/app/id1670203326",
                      "_blank"
                    )
                  }
                  className="z-foreground w-32 transform cursor-pointer transition duration-200 hover:scale-105"
                  alt="downloadOnAppStore"
                  src={downloadOnAppStore}
                />
                <img
                  onClick={() =>
                    window.open(
                      "https://play.google.com/store/apps/details?id=io.dogami.game.academy",
                      "_blank"
                    )
                  }
                  className="z-foreground w-32 transform cursor-pointer transition duration-200 hover:scale-105"
                  alt="downloadOnPlayStore"
                  src={downloadOnPlayStore}
                />
              </div>
            </MobileDisplay>
          </MainTextArea>
        </ResponsiveContainer>
      </MainSectionWrapper>
    </>
  );
};
