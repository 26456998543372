import { ApolloClient, InMemoryCache } from "@apollo/client";

export const formatUrl = (url: string | undefined) => {
  return url && url[url.length - 1] !== "/" ? (url += "/") : url;
};

export default new ApolloClient({
  uri: formatUrl(process.env.REACT_APP_GRAPHQL_URL),
  // uri: "http://localhost:8080/v1/graphql",
  //uri: "https://indexerdev.pve0.scaleway.dogami.com/v1/graphql",
  cache: new InMemoryCache(),
});
