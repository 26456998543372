import { useState } from "react";
import ReactPlayer from "react-player";
import ring from "../../assets/bg/hero-ring.webp";
import playButton from "../../assets/icon/play.svg";
import trailor from "../../assets/image/trailer.jpg";
import { H2, H3 } from "../../page/LandingPage.styled";
import { ModalComponent } from "../ModalComponent/ModalComponent";
import {
  Blur,
  ButtonWrapper,
  DiscoverTheLoreButton,
  LeftTextWrapper,
  LeftTextWrapperMobile,
  MobileDiscoverLoreContainer,
  MobilePlayButtonWrapper,
  MobileRingWrapper,
  MobileTrailorContainer,
  PlayButtonWrapper,
  PurpleShadowWrapperMobile,
  RightTextWrapper,
  RightTextWrapperMobile,
  SmallerImageSection,
  TitleSection,
  TrailorImgWrapper,
  TrailorImgWrapperMobile,
  TrailorMobileContainer,
  TrailorSectionWrapper,
  VideoWrapper,
} from "./TrailorSection.styled";

export const TrailorSection = () => {
  const [openVideoModal, setOpenVideoModal] = useState<boolean>(false);

  const closeModal = () => {
    setOpenVideoModal(false);
  };

  function toggleFullScreen() {
    const video = document.querySelector("video") as HTMLVideoElement;
    if (!document.fullscreenElement) {
      video.requestFullscreen();
    } else if (document.fullscreenElement) {
      document.exitFullscreen();
    }
  }

  return (
    <>
      <TrailorSectionWrapper>
        <TrailorImgWrapper>
          <div>
            <img
              className="background-trailer-image"
              src={trailor}
              alt="dogami-trailor"
            />
          </div>
          <Blur></Blur>
          <TitleSection>
            <H2>
              IT&#39;S A <br />
              DOG&#39;S WORLD
            </H2>
            <H3>
              The Dogamí’s goal is to guide, protect and bond with their human
              companions
            </H3>
          </TitleSection>
          <SmallerImageSection>
            <img
              src={trailor}
              className="foreground-trailor-image"
              alt="dogami-trailor"
            />

            <PlayButtonWrapper onClick={() => setOpenVideoModal(true)}>
              <img src={playButton} alt="" />
            </PlayButtonWrapper>
            <ButtonWrapper onClick={() => setOpenVideoModal(true)}>
              <DiscoverTheLoreButton>DISCOVER THE LORE</DiscoverTheLoreButton>
            </ButtonWrapper>
            <LeftTextWrapper>
              The Dogamí are a pack of mystical dogs with special abilities,
              including the ability to communicate with humans and manipulate
              the elements. Their goal is to preserve and protect all living
              things, build strong lifelong bonds with their companions, and
              guard Earth at all costs.
            </LeftTextWrapper>
            <RightTextWrapper>
              The Dogamí have been tasked with these responsibilities by the
              celestial beings who brought them to Earth in the distant past.
              Despite facing challenges and setbacks throughout history, the
              Dogamí remain dedicated to their goal and continue to serve
              humanity and the Earth to this day.
            </RightTextWrapper>
          </SmallerImageSection>
        </TrailorImgWrapper>

        <TrailorImgWrapperMobile>
          <div>
            <img
              className="background-trailer-image"
              src={trailor}
              alt="dogami-trailor"
            />
          </div>
          <Blur></Blur>
          <TrailorMobileContainer>
            <H2>
              IT&#39;S A <br />
              DOG&#39;S WORLD
            </H2>
            <H3>
              The Dogamí’s goal is to guide, protect and bond with their human
              companions
            </H3>
            <LeftTextWrapperMobile>
              The Dogamí are a pack of mystical dogs with special abilities,
              including the ability to communicate with humans and manipulate
              the elements. Their goal is to preserve and protect all living
              things, build strong lifelong bonds with their companions, and
              guard Earth at all costs.
            </LeftTextWrapperMobile>
            <RightTextWrapperMobile>
              The Dogamí have been tasked with these responsibilities by the
              celestial beings who brought them to Earth in the distant past.
              Despite facing challenges and setbacks throughout history, the
              Dogamí remain dedicated to their goal and continue to serve
              humanity and the Earth to this day.
            </RightTextWrapperMobile>

            <MobileTrailorContainer>
              <MobilePlayButtonWrapper onClick={() => setOpenVideoModal(true)}>
                <img src={playButton} alt="" />
              </MobilePlayButtonWrapper>
              <MobileRingWrapper>
                <img src={ring} alt="" />
              </MobileRingWrapper>
              <PurpleShadowWrapperMobile></PurpleShadowWrapperMobile>
              <MobileDiscoverLoreContainer
                onClick={() => setOpenVideoModal(true)}
              >
                DISCOVER THE LORE
              </MobileDiscoverLoreContainer>
            </MobileTrailorContainer>
          </TrailorMobileContainer>
        </TrailorImgWrapperMobile>
      </TrailorSectionWrapper>

      <ModalComponent
        width={"800px"}
        height={"auto"}
        closeModal={closeModal}
        display={openVideoModal}
      >
        <VideoWrapper>
          <ReactPlayer
            className="player"
            url="https://s3.amazonaws.com/statics.dogami.com/landing/Animatique_DOGAMI_TRUE_COMPAGNON_x264.mp4"
            width="100%"
            height="100%"
            playing
            controls
            autohide
            onStart={toggleFullScreen}
          />
        </VideoWrapper>
      </ModalComponent>
    </>
  );
};
