import { ApolloProvider } from "@apollo/client";

import "@pathofdev/react-tag-input/build/index.css";

import { FC } from "react";

import ReactGA from "react-ga4";

import "react-loading-skeleton/dist/skeleton.css";

import "react-notifications-component/dist/theme.css";

import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";

import "react-toastify/dist/ReactToastify.css";

import apolloClient from "./api/client";

import { LayoutWrapper, MainWrapper } from "./app.styled";

import { LandingPage } from "./page/LandingPage";

import { I18nextProvider } from "react-i18next";

import i18n from "./i18";

const TRACKING_ID = "UA-205759029-1";
ReactGA.initialize(TRACKING_ID);

const App: FC = () => {
  if (process.env.REACT_APP_NETWORK == "mainnet") console.log = () => {};

  return (
    <I18nextProvider i18n={i18n}>
      <ApolloProvider client={apolloClient}>
        <MainWrapper>
          <Router>
            <LayoutWrapper>
              <Switch>
                <Route exact component={LandingPage} path="/" />
                <Redirect from="*" to="/" />
              </Switch>
            </LayoutWrapper>
          </Router>
        </MainWrapper>
      </ApolloProvider>
    </I18nextProvider>
  );
};

export default App;
