import styled from "styled-components";
import Ring from "../../assets/bg/hero-ring.webp";
import trailor from "../../assets/image/trailer.jpg";

export const TrailorSectionWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 840px;
`;

export const Blur = styled.div`
  backdrop-filter: blur(8px);
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  min-height: 840px !important;
`;

export const TitleSection = styled.div`
  z-index: 9;
  position: absolute;
  top: 5%;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;

  h2 {
    font-size: 64px !important;
    line-height: 72px;
  }

  h3 {
    width: 560px;
    text-align: center;
  }
`;

export const LeftTextWrapperMobile = styled.div`
  z-index: 2;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  text-transform: none !important;
  line-height: 24px;
  font-size: 16px !important;
  font-family: "Montserrat", sans-serif !important;
`;

export const RightTextWrapperMobile = styled.div`
  z-index: 2;
  width: 100%;
  display: flex;
  font-size: 16px;
  line-height: 24px;
  align-items: flex-start;
  flex-direction: column;
  text-transform: none !important;
`;

export const SmallerImageSection = styled.div`
  z-index: 2;
  position: absolute;
  top: 144px;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;

  .foreground-trailor-image {
    box-shadow: 0 0 160px #800299;
    border-radius: 8px;
    cursor: pointer;
  }

  img {
    width: 63%;
    max-width: 1024px;
  }

  @media only screen and (max-width: 900px) {
    top: 450px;

    img {
      width: 40%;
    }
  }

  @media only screen and (max-width: 720px) {
    top: 70%;
    img {
      width: 50%;
    }
  }

  @media only screen and (max-width: 480px) {
    top: 77%;

    img {
      width: 55%;
    }
  }
`;

export const MobileTrailorContainer = styled.div`
  position: relative;
  width: 272px;
  height: 153px;
  background-size: 100% 100%;
  background-position: bottom center;
  background-repeat: no-repeat;
  background-image: url(${trailor});
  box-shadow: 0 0 64px #800299;
  border-radius: 4px;
`;

export const MobileDiscoverLoreContainer = styled.div`
  position: absolute;
  top: 110px;
  left: 48px;
  color: #220234;
  background: white;
  width: 181px;
  padding: 8px 0px;
  text-align: center;
  border-radius: 12px;
  font-weight: 700;
  cursor: pointer;

  &:hover {
    color: #ff0060;
  }
`;

export const MobileImageTrailerContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 272px;
  }
`;

export const FirstLayerRingWrapper = styled.div`
  z-index: 2;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;

  img {
    width: 6%;
    margin: 0 2px 2px 0;
  }
`;

export const SecondLayerRingWrapper = styled.div`
  z-index: 2;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;

  img {
    width: 6%;
    margin: 0 1px 1px 0;
  }
`;

export const PurpleShadowWrapper = styled.div`
  z-index: 2;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
`;

export const PurpleShadow = styled.div`
  z-index: 2;
  width: 72px;
  height: 72px;
  background: #220234;
  border-radius: 50%;
  box-shadow: inset 0 0 28px #d9bee8;

  @media only screen and (max-width: 3000px) {
    width: 124px;
    height: 124px;
    box-shadow: inset 0 0 60px #d9bee8;
  }

  @media only screen and (max-width: 2600px) {
    width: 116px;
    height: 116px;
    box-shadow: inset 0 0 50px #d9bee8;
  }

  @media only screen and (max-width: 2200px) {
    width: 100px;
    height: 100px;
    box-shadow: inset 0 0 46px #d9bee8;
  }

  @media only screen and (max-width: 2000px) {
    width: 92px;
    height: 92px;
    box-shadow: inset 0 0 40px #d9bee8;
  }

  @media only screen and (max-width: 1800px) {
    width: 86px;
    height: 86px;
    box-shadow: inset 0 0 40px #d9bee8;
  }

  @media only screen and (max-width: 1600px) {
    width: 78px;
    height: 78px;
    box-shadow: inset 0 0 32px #d9bee8;
  }

  @media only screen and (max-width: 1400px) {
    width: 66px;
    height: 66px;
    box-shadow: inset 0 0 32px #d9bee8;
  }

  @media only screen and (max-width: 1200px) {
    width: 56px;
    height: 56px;
    box-shadow: inset 0 0 20px #d9bee8;
  }

  @media only screen and (max-width: 1000px) {
    width: 48px;
    height: 48px;
    box-shadow: inset 0 0 16px #d9bee8;
  }
`;

export const PlayButtonWrapper = styled.div`
  z-index: 10;
  position: absolute;
  top: 8px;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;

  img {
    width: 2%;
    margin: 0 1px 16px 4px;
    cursor: pointer;
  }
`;

export const PurpleShadowWrapperMobile = styled.div`
  z-index: 1;
  position: absolute;
  top: 16px;
  left: 108px;
  border-radius: 50%;
  width: 56px;
  height: 56px;
  background: #220234;
  box-shadow: inset 0 0 24px #d9bee8;
`;

export const MobileRingWrapper = styled.div`
  z-index: 2;
  position: absolute;
  top: 12px;
  left: 104px;
  border-radius: 50%;

  img {
    width: 64px;
  }
`;

export const MobilePlayButtonWrapper = styled.div`
  z-index: 10;
  position: absolute;
  top: 30px;
  left: 126px;

  img {
    width: 24px;
    cursor: pointer;
  }
`;

export const TrailorMobileContainer = styled.div`
  position: absolute;
  z-index: 10;
  top: 0;
  left: 0;
  height: 100%;
  margin: 32px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;

  div {
    margin-top: 24px;
    text-transform: none;
  }

  h3 {
    align-self: baseline;
  }
`;

export const ButtonWrapper = styled.div`
  z-index: 10;
  position: absolute;
  cursor: pointer;
  top: 80px;
  left: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ButtonWrapperMobile = styled.div`
  z-index: 10;
  position: absolute;
  bottom: -96px;
  left: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const DiscoverTheLoreButton = styled.div`
  cursor: pointer;
  padding: 8px 16px 8px 16px;
  background: white;
  color: #220234;
  border-radius: 12px;
  font-size: 12px;
  font-weight: 700;

  &:hover {
    color: #ff0060;
  }
`;

export const RightTextWrapper = styled.div`
  z-index: 10;
  position: absolute;
  top: 85%;
  left: 50%;
  width: 376px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: left;
  text-transform: none !important;
  font-size: 16px;
  line-height: 24px;
  font-family: "Montserrat", sans-serif;
`;

export const LeftTextWrapper = styled.div`
  z-index: 10;
  position: absolute;
  top: 48%;
  left: 12%;
  width: 272px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: left;
  text-transform: none !important;
  font-size: 16px;
  line-height: 24px;
  font-family: "Montserrat", sans-serif;

  @media only screen and (min-width: 1600px) {
    left: 20%;
  }

  @media only screen and (max-width: 1044px) {
    top: 85%;
    left: 11%;
    width: 307px;
  }
`;

export const TrailorImgWrapperMobile = styled.div`
  position: relative;
  width: 100vw;
  min-height: 726px;

  @media only screen and (min-width: 901px) {
    display: none;
  }

  .background-trailer-image {
    width: 100%;
    height: 840px;
    object-fit: cover;
  }
`;

export const TrailorImgWrapper = styled.div`
  position: relative;
  width: 100vw;
  height: 100%;

  @media only screen and (max-width: 900px) {
    display: none;
  }

  .background-trailer-image {
    width: 100%;
    height: 840px;s
    object-fit: cover;
  }

  .fade-in-section {
    opacity: 0;
    transform: scale(0.5);
    visibility: hidden;
    transition: opacity 3s ease-out, transform 600ms ease-in-out,
      visibility 3s ease-out;
    will-change: opacity, transform, visibility;
  }
  .fade-in-section.is-visible {
    opacity: 1;
    transform: none;
    visibility: visible;
  }
`;

export const SecondLayer = styled.div`
  position: absolute;
  top: 0;
  height: 100%;
  width: 1424px;
  @media only screen and (max-width: 1440px) {
    width: 1136px;
  }
  @media only screen and (max-width: 1136px) {
    width: 100%;
  }
`;

export const TrailorContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  position: relative;
  padding: 0 5%;
  @media only screen and (max-width: 1280px) {
    flex-direction: column;
  }
  @media only screen and (max-width: 900px) {
    width: 100%;
    height: 100%;
    padding: 0;
  }
`;

export const VideoWrapper = styled.div`
  width: 100%;
  height: 100%;
  margin-top: 3rem;
`;

export const RingContainer = styled.div`
  width: 17rem;
  height: 17rem;
  @media only screen and (max-width: 2880px) {
    width: calc(100vw * 0.09);
    height: calc(100vw * 0.09);
  }
  @media only screen and (max-width: 1660px) {
    width: calc(100vw * 0.1);
    height: calc(100vw * 0.1);
  }
  @media only screen and (max-width: 1366px) {
    width: calc(100vw * 0.12);
    height: calc(100vw * 0.12);
  }
  @media only screen and (max-width: 1280px) {
    width: calc(100vh * 0.17);
    height: calc(100vh * 0.17);
  }
  @media only screen and (max-width: 900px) {
    width: calc(100vw * 0.28);
    height: calc(100vw * 0.28);
  }
`;

export const GlowingRing = styled.div`
  cursor: pointer;
  width: 100%;
  height: 100%;
  mix-blend-mode: screen;
  transform-origin: center center;
  transform: scale(0.97);
  background-image: url(${Ring});
  background-size: cover;
  background-position-y: bottom;
  animation: grow 50s linear infinite alternate-reverse;
  @keyframes grow {
    0% {
      transform: scale(0.97) rotate(0deg);
    }
    4% {
      transform: scale(1.03) rotate(14deg);
    }
    8% {
      transform: scale(0.97) rotate(29deg);
    }
    12% {
      transform: scale(1.03) rotate(43deg);
    }
    16% {
      transform: scale(0.97) rotate(58deg);
    }
    20% {
      transform: scale(1.03) rotate(72deg);
    }
    24% {
      transform: scale(0.97) rotate(86deg);
    }
    28% {
      transform: scale(1.03) rotate(101deg);
    }
    32% {
      transform: scale(0.97) rotate(115deg);
    }
    36% {
      transform: scale(1.03) rotate(130deg);
    }
    40% {
      transform: scale(0.97) rotate(144deg);
    }
    44% {
      transform: scale(1.03) rotate(158deg);
    }
    48% {
      transform: scale(0.97) rotate(173deg);
    }
    52% {
      transform: scale(1.03) rotate(187deg);
    }
    56% {
      transform: scale(0.97) rotate(202deg);
    }
    60% {
      transform: scale(1.03) rotate(216deg);
    }
    64% {
      transform: scale(0.97) rotate(230deg);
    }
    68% {
      transform: scale(1.03) rotate(245deg);
    }
    72% {
      transform: scale(0.97) rotate(259deg);
    }
    76% {
      transform: scale(1.03) rotate(274deg);
    }
    80% {
      transform: scale(0.97) rotate(288deg);
    }
    84% {
      transform: scale(1.03) rotate(302deg);
    }
    88% {
      transform: scale(0.97) rotate(317deg);
    }
    92% {
      transform: scale(1.03) rotate(331deg);
    }
    96% {
      transform: scale(0.97) rotate(346deg);
    }
    100% {
      transform: scale(1.03) rotate(360deg);
    }
  }
  @keyframes blink {
    0% {
      opacity: 0.14;
    }
    4% {
      opacity: 0.33;
    }
    8% {
      opacity: 0.38;
    }
    12% {
      opacity: 0.36;
    }
    16% {
      opacity: 0.49;
    }
    20% {
      opacity: 0.46;
    }
    24% {
      opacity: 0.44;
    }
    28% {
      opacity: 0.2;
    }
    32% {
      opacity: 0.47;
    }
    36% {
      opacity: 0.22;
    }
    40% {
      opacity: 0.22;
    }
    44% {
      opacity: 0.41;
    }
    48% {
      opacity: 0.2;
    }
    52% {
      opacity: 0.17;
    }
    56% {
      opacity: 0.41;
    }
    60% {
      opacity: 0.28;
    }
    64% {
      opacity: 0.34;
    }
    68% {
      opacity: 0.44;
    }
    72% {
      opacity: 0.34;
    }
    76% {
      opacity: 0.22;
    }
    80% {
      opacity: 0.49;
    }
    84% {
      opacity: 0.3;
    }
    88% {
      opacity: 0.39;
    }
    92% {
      opacity: 0.1;
    }
    96% {
      opacity: 0.38;
    }
  }
  @keyframes blinkslow {
    7% {
      opacity: 0;
    }
    8% {
      opacity: 0.8;
    }
    9% {
      opacity: 0;
    }

    28% {
      opacity: 0;
    }
    29% {
      opacity: 0.8;
    }
    30% {
      opacity: 0;
    }

    40% {
      opacity: 0;
    }
    41% {
      opacity: 0.8;
    }
    42% {
      opacity: 0;
    }
    44% {
      opacity: 0.8;
    }
    45% {
      opacity: 0;
    }

    68% {
      opacity: 0;
    }
    69% {
      opacity: 0.8;
    }
    70% {
      opacity: 0;
    }

    85% {
      opacity: 0;
    }
    86% {
      opacity: 0.8;
    }
    87% {
      opacity: 0;
    }
    88% {
      opacity: 0.4;
    }
    89% {
      opacity: 0;
    }
  }
`;
