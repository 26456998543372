import DogamiNotGap from "../../assets/image/Partnership.jpg";

import Dog from "../../assets/image/DogPartner.png";

import {
  H2,
  H3,
  Pwhite,
  ResponsiveContainer,
  SectionWrapper,
} from "../../page/LandingPage.styled";
import {
  LeftArea,
  LeftAreaContainer,
  PartnershipWrapper,
  PicturesArea,
  PicturesContainer,
} from "./PartershipSection.styled";

export const PartnershipSection = () => {
  return (
    <>
      <SectionWrapper>
        <ResponsiveContainer>
          <PartnershipWrapper>
            <LeftAreaContainer>
              <LeftArea>
                <H2>
                  GLOBAL <br />
                  PARTNERSHIPS
                </H2>
                <H3>
                  Exclusive brand NFTS in the <br />
                  Petaverse
                </H3>
                <Pwhite>
                  DOGAMÍ partners with like-minded brands from around the world
                  in order to introduce exclusive NFTs, personalize your avatars
                  and unlock secret game rewards.
                </Pwhite>
              </LeftArea>
            </LeftAreaContainer>
            <PicturesContainer>
              <PicturesArea>
                <img src={DogamiNotGap} />
                <img src={Dog} />
              </PicturesArea>
            </PicturesContainer>
          </PartnershipWrapper>
        </ResponsiveContainer>
      </SectionWrapper>
    </>
  );
};
