export const ChevronIcon = ({ iconClass, onClickIcon }: any) => {
  const computeIconClass = () => {
    return iconClass ? iconClass : "fill-white";
  };

  return (
    <svg
      onClick={() => onClickIcon()}
      className={`${computeIconClass()}`}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
    >
      <path
        fillRule="nonzero"
        d="M17.9208,12.3723 C17.9165,11.97382 17.7677,11.63047 17.4523,11.32672 L8.32498,2.38484 C8.0592,2.128671 7.74233,2 7.3596,2 C6.605854,2 6,2.592026 6,3.35327 C6,3.72428 6.150936,4.06764 6.412261,4.33435 L14.65243,12.3701 L6.412261,20.4102 C6.153045,20.6748 6,21.0106 6,21.3934 C6,22.1525 6.605854,22.7466 7.3596,22.7466 C7.73811,22.7466 8.0592,22.618 8.32498,22.3522 L17.4523,13.4178 C17.7698,13.1044 17.9208,12.7686 17.9208,12.3723 Z"
      />
    </svg>
  );
};
