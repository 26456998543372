export const LinkedinIcon = ({ iconClass }: any) => {
  const computeIconClass = () => {
    return iconClass ? iconClass : "";
  };

  return (
    <svg
      className={`${computeIconClass()}`}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
    >
      <path
        fillRule="nonzero"
        d="M22.4,0 L1.6,0 C1.175656,0 0.7686872,0.1685712 0.4686288,0.4686288 C0.1685712,0.7686872 0,1.175656 0,1.6 L0,22.4 C0,22.82432 0.1685712,23.23128 0.4686288,23.53136 C0.7686872,23.83144 1.175656,24 1.6,24 L22.4,24 C22.82432,24 23.23128,23.83144 23.53136,23.53136 C23.83144,23.23128 24,22.82432 24,22.4 L24,1.6 C24,1.175656 23.83144,0.7686872 23.53136,0.4686288 C23.23128,0.1685712 22.82432,0 22.4,0 Z M7.1096,20.4752 L3.4992,20.4752 L3.4992,9 L7.1096,9 L7.1096,20.4752 Z M5.304,7.42481225 C4.888936,7.423536 4.483552,7.299296 4.139048,7.067776 C3.794552,6.836264 3.526384,6.507848 3.368424,6.124008 C3.210456,5.740176 3.169784,5.318136 3.251536,4.9112 C3.333288,4.504264 3.533792,4.13068 3.827736,3.837624 C4.12168,3.544576 4.495872,3.345208 4.903056,3.264696 C5.31024,3.184184 5.732152,3.226144 6.115504,3.385272 C6.498856,3.5444 6.826456,3.813568 7.056928,4.158768 C7.287392,4.503976 7.41040015,4.909736 7.41040015,5.3248 C7.410504,5.601144 7.356048,5.874792 7.250152,6.13004 C7.144256,6.385288 6.989,6.61712 6.793296,6.812224 C6.597592,7.007336 6.365296,7.16188 6.109728,7.267 C5.854152,7.37212 5.580344,7.425744 5.304,7.42481225 L5.304,7.42481225 Z M20.4968,20.4752 L16.96,20.4752 L16.96,14.9248 C16.96,13.5752 16.96,11.9248 15.08,11.9248 C13.2,11.9248 12.9736,13.3504 12.9736,14.8496 L12.9736,20.5504 L9.44,20.5504 L9.44,9 L12.7488,9 L12.7488,10.5752 L12.824,10.5752 C13.16528,9.9852 13.66048,9.49904 14.25664,9.16872 C14.85288,8.83848 15.5276,8.6764 16.2088,8.7 C19.8192,8.7 20.496,11.1 20.496,14.1752 L20.4968,20.4752 Z"
      />
    </svg>
  );
};
