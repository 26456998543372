import { AdoptButtonWrapper, BtnDiv, ButtonText } from "./AdoptButton.styled";

import ArrowRight from "../../assets/icon/arrowRight-Pink.svg";

export const AdoptButton = (onClick: any) => {
  return (
    <AdoptButtonWrapper>
      <a
        onClick={() => onClick()}
        href={"https://opensea.io/dogami_Deployer/created"}
        target="_parent"
      >
        <BtnDiv>
          <ButtonText>
            Adopt now <img src={ArrowRight} alt="white-arrow" />
          </ButtonText>
          <div className="mask-light">
            <div className="cta-light-blue" />
            <div className="cta-light-pink" />
          </div>
        </BtnDiv>
      </a>
    </AdoptButtonWrapper>
  );
};
