import { SecondarySmallWhite } from "./SecondarySmallWhite/SecondarySmallWhite";

export default function ConnectButton() {
  return (
    <SecondarySmallWhite
      icon={<></>}
      onClick={() => {
        window.open(
          "https://marketplace.dogami.com?openAuthModal=true",
          "_blank"
        );
      }}
    >
      log in
    </SecondarySmallWhite>
  );
}
