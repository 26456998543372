import {
  BackgroundContainer,
  ImageContainer,
  MainNewsletterContainer,
  NewsletterContainer,
  NewsletterTitle,
} from "./Newsletter.styled";

import email from "../../assets/icon/email.svg";

import { useForm } from "react-hook-form";

import { toast } from "react-toastify";
import { registerToNewsletter } from "../../api/backend";

const Newsletter = () => {
  const { register, getValues } = useForm({
    mode: "onChange",
    defaultValues: {
      email: "",
    },
  });

  const onClickSubscribe = () => {
    registerToNewsletter(getValues().email)
      .then(() => {
        toast.success("Go to your email to validate the subscription");
      })
      .catch((e) => {
        toast.error("Something went wrong with the newsletter subscription");
        console.error("Error while registering to newsletter", e);
      });
  };

  return (
    <MainNewsletterContainer id="mc_embed_signup">
      <BackgroundContainer></BackgroundContainer>
      <ImageContainer></ImageContainer>
      <NewsletterContainer>
        <NewsletterTitle>
          JOIN IN - STAY AHEAD WITH EVERY EXCLUSIVE{" "}
        </NewsletterTitle>

        <div
          style={{
            width: "100%",
            maxWidth: "360px",
            position: "relative",
            marginTop: "24px",
            display: "flex",
            fontFamily: "Montserrat, sans-serif",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            src={email}
            style={{
              position: "absolute",
              top: "19px",
              left: "18px",
              width: "12px",
            }}
          />

          <input
            style={{
              width: "100%",
              fontSize: "12px",
              display: "flex",
              border: "1px solid rgba(255, 255, 255, 0.3)",
              backgroundColor: "#1B022A",
              borderRadius: "16px",
              padding: "16px 40px",
              fontFamily: "Montserrat, sans-serif",
              color: "white",
            }}
            type="string"
            placeholder="Enter your email"
            {...register("email", {
              required: {
                value: true,
                message: "Email is required",
              },
            })}
          />

          <div
            onClick={() => onClickSubscribe()}
            style={{
              width: "104px",
              height: "32px",
              background: "white",
              fontFamily: "Montserrat, sans-serif",
              fontWeight: "700",
              color: "black",
              position: "absolute",
              top: "8px",
              right: "8px",
              borderRadius: "12px",
              cursor: "pointer",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            SUBSCRIBE
          </div>
        </div>
      </NewsletterContainer>
    </MainNewsletterContainer>
  );
};

export default Newsletter;
