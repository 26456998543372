export const ArrowIcon = ({ iconClass }: any) => {
  return (
    <svg
      className={iconClass}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
    >
      <path
        fillRule="nonzero"
        d="M23.1414,12.07265 C23.1414,11.71851 22.9968,11.37515 22.7308,11.11875 L15.0119,3.421169 C14.7161,3.12328 14.4088,3 14.0791,3 C13.353,3 12.8254,3.518902 12.8254,4.22507 C12.8254,4.58858 12.9634,4.90241 13.1973,5.1342 L15.8223,7.79342 L20.1963,11.79304 L20.6036,11.03203 L16.5496,10.79344 L2.2928,10.79344 C1.523121,10.79344 1,11.31773 1,12.07265 C1,12.82756 1.523121,13.3519 2.2928,13.3519 L16.5496,13.3519 L20.6036,13.1133 L20.1963,12.36397 L15.8223,16.3519 L13.1973,19.009 C12.9634,19.2291 12.8254,19.5546 12.8254,19.9181 C12.8254,20.6243 13.353,21.1432 14.0791,21.1432 C14.4088,21.1432 14.7161,21.0103 14.9926,20.7412 L22.7308,13.0265 C22.9968,12.77014 23.1414,12.42679 23.1414,12.07265 Z"
      />
    </svg>
  );
};
