import xml2js from "xml2js";

import { useEffect, useRef, useState } from "react";

import { LandingMain } from "../components/01_FirstView/FirstView";

import { AdoptSection } from "../components/02_AdoptDogamiNFTs/AdoptSection";

import { RaiseSection } from "../components/03_RaiseYourPuppy/RaiseSection";

import { EarnSection } from "../components/04_EarnRewards/EarnSection";

import { TrailorSection } from "../components/05_Trailor/TrailorSection";

import { PartnershipSection } from "../components/06_GlobalPartnership/PartershipSection";

import { NewInvestorSection } from "../components/08_NewInvestor/NewInvestor";

import { DataSection } from "../components/09_Data/DataSection";

import { FeaturedSection } from "../components/10_Featured/FeaturedSection";

import Newsletter from "../components/13_Newsletter/Newsletter";

import { LandingPageBg } from "./LandingPage.styled";

import { getMediumXML } from "../api/backend";

import { NewsSection } from "../components/14_NewsSection/NewsSection";

import { Header } from "../components/Header/Header";

import { ToastContainer } from "react-toastify";

import { Footer } from "../components/99_Footer/Footer";

import i18n from "i18next";

export type MediumArticle = {
  title: string;
  link: string;
  date: Date;
  image: string;
};

export const LandingPage = () => {
  const [mediumArticle, setMediumArticles] = useState<Array<MediumArticle>>([]);

  let homeRef = useRef<HTMLDivElement>(null);
  let adoptRef = useRef<HTMLDivElement>(null);
  let raiseRef = useRef<HTMLDivElement>(null);
  let earnRef = useRef<HTMLDivElement>(null);
  let newsRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const loc = Intl.DateTimeFormat().resolvedOptions().locale || "en-US";

    console.log("LOC : ", loc);

    i18n.changeLanguage(loc?.substring(0, 2) || "en");

    getMediumXML().then((value: any) => {
      xml2js.parseString(value.data, (error, result) => {
        if (error) {
          return;
        }

        const rssData = result.rss.channel[0].item;

        rssData.forEach((item: any) => {
          const title = item.title[0];
          const date = item.pubDate[0];

          const srcRegex = /<img[^>]+src=["']([^"']+)["']/;

          const match = item["content:encoded"][0].match(srcRegex);

          const content = match ? match[1] : null;

          const link = item.link[0];

          setMediumArticles((prev) => {
            return [
              ...prev,
              { title, date: new Date(date), image: content, link },
            ];
          });
        });
      });
    });
  }, []);

  return (
    <>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        newestOnTop={false}
        closeOnClick
        pauseOnFocusLoss
        draggable
        pauseOnHover
        style={{ zIndex: 999999 }}
      />
      <LandingPageBg>
        <Header></Header>
        <section ref={homeRef}>
          <LandingMain />
        </section>
        <section ref={raiseRef}>
          <RaiseSection />
        </section>
        <section ref={adoptRef}>
          <AdoptSection />
        </section>
        <section ref={earnRef} style={{ zIndex: "1" }}>
          <EarnSection />
        </section>
        <section ref={newsRef}>
          <NewsSection articles={mediumArticle} />
        </section>
        <TrailorSection />
        <PartnershipSection />
        <NewInvestorSection />
        <DataSection />
        <FeaturedSection />
        <Newsletter />
        <Footer />
      </LandingPageBg>
    </>
  );
};
