import { ArrowIcon } from "./04_EarnRewards/ArrowIcon";
import { PrimarySmallWhiteButton } from "./PrimarySmallWhite/PrimarySmallWhite";

export const SignUpButton = () => {
  return (
    <PrimarySmallWhiteButton
      icon={<ArrowIcon iconClass="fill-pink ml-2 w-3"></ArrowIcon>}
      onClick={() => {
        window.open("https://marketplace.dogami.com/auth", "_blank");
      }}
    >
      create account
    </PrimarySmallWhiteButton>
  );
};
