import { motion } from "framer-motion";
import styled from "styled-components";
import Ring from "../../assets/bg/hero-ring.webp";

import newHero from "../../assets/Hero.jpg";

export const MainSectionWrapper = styled.div`
  min-height: 100vh;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin 0 auto;

  background-image: url(${newHero});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  @media only screen and (max-width: 1000px) {
    flex-direction: column-reverse;
    height: auto;
  }
`;

export const MainTextArea = styled.div`
  padding: 0 0 0 5%;

  @media only screen and (max-width: 500px) {
    margin-top: -70px;
  }
`;

export const DesktopDisplay = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0rem;

  H1 {
    margin-top: -8px;
  }

  @media only screen and (max-width: 500px) {
    display: none;
  }
`;

export const MobileDisplay = styled.div`
  display: none;

  @media only screen and (max-width: 500px) {
    display: flex;
    flex-direction: column;
    gap: 0rem;

    H1 {
      margin-top: 32px;
    }
  }

  margin-bottom: 24px;
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;

  @media only screen and (max-width: 500px) {
    width: 100%;
    align-items: flex-start;
    flex-direction: column;
  }
`;

export const HowToPlayMobileWrapper = styled.div`
  position: absolute;
  top: 124px;
  right: 24px;
  z-index: 99;

  @media only screen and (min-width: 1000px) {
    display: none;
  }
`;

export const BgArea = styled(motion.div)`
  width: 100%;
  position: relative;
`;

export const Picture = styled.picture`
  position: absolute;
  width: auto;
  height: 100%;
  right: 20%;
  img {
    height: 100%;
    width: auto;
  }
  @media only screen and (max-width: 600px) {
    right: -15%;
  }
  @media only screen and (max-width: 400px) {
    right: -20%;
  }
`;

export const DogAndBackgroundWrapper = styled.div`
  width: fit-content;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;

  .dogami-hero-image {
    height: 100vh;
    width: 110%;

    @media only screen and (max-width: 1000px) {
      height: 70vh;
      width: auto;
    }
  }

  @media only screen and (max-width: 1250px) {
    width: 100%;
  }
`;

export const BackgroundWrapper = styled.div`
  position: absolute;
  top: 0;
  left: -40px;
  width: 100%;
  height: 100%;
  align-items: flex-end;
  justify-content: center;
  display: flex;
  z-index: 2;

  @media only screen and (max-width: 1000px) {
    left: -18px;
  }

  .background-image {
    width: 320px;

    @media only screen and (max-width: 1000px) {
      width: 240px;
    }

    @media only screen and (max-width: 600px) {
      width: 200px;
    }

    @media only screen and (max-width: 380px) {
      width: 160px;
    }
  }
`;

export const RingContainer = styled.div`
  position: absolute;
  top: 15rem;
  left: -32px;
  z-index: 1;
  width: 100%;
  display: flex;
  justify-content: center;

  @media only screen and (max-width: 1000px) {
    top: 160px;
    left: -26px;
  }

  @media only screen and (max-width: 600px) {
    left: -20px;
  }

  @media only screen and (max-width: 500px) {
    left: -8px;
  }
`;

export const GlowingRing = styled.div`
  position: relative;
  width: 320px;
  height: 320px;

  @media only screen and (max-width: 1000px) {
    width: 240px;
    height: 240px;
  }

  @media only screen and (max-width: 800px) {
    width: 180px;
    height: 180px;
  }

  mix-blend-mode: screen;
  transform-origin: center center;
  transform: scale(0.97);
  background-image: url(${Ring});
  background-size: cover;
  background-position-y: bottom;
  animation: grow 50s linear infinite alternate-reverse;
  @keyframes grow {
    0% {
      transform: scale(0.97) rotate(0deg);
    }
    4% {
      transform: scale(1.03) rotate(14deg);
    }
    8% {
      transform: scale(0.97) rotate(29deg);
    }
    12% {
      transform: scale(1.03) rotate(43deg);
    }
    16% {
      transform: scale(0.97) rotate(58deg);
    }
    20% {
      transform: scale(1.03) rotate(72deg);
    }
    24% {
      transform: scale(0.97) rotate(86deg);
    }
    28% {
      transform: scale(1.03) rotate(101deg);
    }
    32% {
      transform: scale(0.97) rotate(115deg);
    }
    36% {
      transform: scale(1.03) rotate(130deg);
    }
    40% {
      transform: scale(0.97) rotate(144deg);
    }
    44% {
      transform: scale(1.03) rotate(158deg);
    }
    48% {
      transform: scale(0.97) rotate(173deg);
    }
    52% {
      transform: scale(1.03) rotate(187deg);
    }
    56% {
      transform: scale(0.97) rotate(202deg);
    }
    60% {
      transform: scale(1.03) rotate(216deg);
    }
    64% {
      transform: scale(0.97) rotate(230deg);
    }
    68% {
      transform: scale(1.03) rotate(245deg);
    }
    72% {
      transform: scale(0.97) rotate(259deg);
    }
    76% {
      transform: scale(1.03) rotate(274deg);
    }
    80% {
      transform: scale(0.97) rotate(288deg);
    }
    84% {
      transform: scale(1.03) rotate(302deg);
    }
    88% {
      transform: scale(0.97) rotate(317deg);
    }
    92% {
      transform: scale(1.03) rotate(331deg);
    }
    96% {
      transform: scale(0.97) rotate(346deg);
    }
    100% {
      transform: scale(1.03) rotate(360deg);
    }
  }
  @keyframes blink {
    0% {
      opacity: 0.14;
    }
    4% {
      opacity: 0.33;
    }
    8% {
      opacity: 0.38;
    }
    12% {
      opacity: 0.36;
    }
    16% {
      opacity: 0.49;
    }
    20% {
      opacity: 0.46;
    }
    24% {
      opacity: 0.44;
    }
    28% {
      opacity: 0.2;
    }
    32% {
      opacity: 0.47;
    }
    36% {
      opacity: 0.22;
    }
    40% {
      opacity: 0.22;
    }
    44% {
      opacity: 0.41;
    }
    48% {
      opacity: 0.2;
    }
    52% {
      opacity: 0.17;
    }
    56% {
      opacity: 0.41;
    }
    60% {
      opacity: 0.28;
    }
    64% {
      opacity: 0.34;
    }
    68% {
      opacity: 0.44;
    }
    72% {
      opacity: 0.34;
    }
    76% {
      opacity: 0.22;
    }
    80% {
      opacity: 0.49;
    }
    84% {
      opacity: 0.3;
    }
    88% {
      opacity: 0.39;
    }
    92% {
      opacity: 0.1;
    }
    96% {
      opacity: 0.38;
    }
  }
  @keyframes blinkslow {
    7% {
      opacity: 0;
    }
    8% {
      opacity: 0.8;
    }
    9% {
      opacity: 0;
    }

    28% {
      opacity: 0;
    }
    29% {
      opacity: 0.8;
    }
    30% {
      opacity: 0;
    }

    40% {
      opacity: 0;
    }
    41% {
      opacity: 0.8;
    }
    42% {
      opacity: 0;
    }
    44% {
      opacity: 0.8;
    }
    45% {
      opacity: 0;
    }

    68% {
      opacity: 0;
    }
    69% {
      opacity: 0.8;
    }
    70% {
      opacity: 0;
    }

    85% {
      opacity: 0;
    }
    86% {
      opacity: 0.8;
    }
    87% {
      opacity: 0;
    }
    88% {
      opacity: 0.4;
    }
    89% {
      opacity: 0;
    }
  }
`;

export const Cursor = styled(motion.div)`
  z-index: 1;
  position: relative;
  width: 96px;
  left: 0;
  bottom: 400px;
  height: 48px;
  border-radius: 50%;
  background-color: transparent;
  border: 1px solid #ffffff;
  opacity: 0.3;
  div {
    position: absolute;
    bottom: 15%;
    left: 36%;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    border: 2px solid #ff0060;
    box-shadow: 0px 0px 8px rgba(255, 0, 96, 1);
    opacity: 1;
  }
  p {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 200px;
    opacity: 1;
    font-size: 8px;
    font-weight: 400;
    text-align: center;
    text-transform: uppercase;
  }
  @media only screen and (max-width: 601px) {
    display: none;
  }
`;
