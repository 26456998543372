import styled from "styled-components";

export const PartnershipWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 5% 0 10% 2%;
  gap: 2rem;

  br {
    display: none;
  }

  @media only screen and (max-width: 1200px) {
    margin: 0 0 32px 0;
    gap: 3rem;
    flex-direction: column;
    text-align: left;
    align-items: center;
    h2,
    h3,
    p {
      width: 100%;
    }
  }

  @media only screen and (max-width: 900px) {
    padding: 0 0 10% 2%;
  }

  @media only screen and (max-width: 600px) {
    padding: 10% 0 10% 2%;
  }
`;

export const LeftAreaContainer = styled.div`
  display: flex;
  width: 100%;
`;

export const LeftArea = styled.div`
  display: flex;
  flex-direction: column;
  width: 391px;
  margin: 0 10% 0 10%;

  h3 {
    margin-bottom: 8px;
  }

  @media only screen and (max-width: 1200px) {
    margin: 0;
    width: 100%;
    h3,
    p {
      width: 90%;
    }
  }

  @media only screen and (max-width: 601px) {
    min-width: 0;
    text-align: left;
    padding: 0 5%;
    br {
      display: none !important;
    }
  }
  @media only screen and (max-width: 428px) {
    padding: 0 1rem;
  }
`;

export const PicturesArea = styled.div`
  position: relative;
  width: 560px;
  height: 384px;

  @media only screen and (max-width: 600px) {
    width: 100%;
    height: 320px;
  }

  @media only screen and (max-width: 500px) {
    height: 264px;
  }

  @media only screen and (max-width: 400px) {
    height: 216px;
  }

  img:first-child {
    position: absolute;
    width: 100%;
    bottom: 0px;
    left: 0px;
    border-radius: 8px;

    @media only screen and (max-width: 800px) {
      width: 90%;
      margin: 0 16px;
    }
  }

  img:last-child {
    position: absolute;
    width: 44%;
    bottom: -110px;
    left: -90px;

    @media only screen and (max-width: 1044px) {
      display: none;
    }
  }
`;

export const PicturesContainer = styled.div`
  width: 100%;
  height: 100%;

  @media only screen and (max-width: 1200px) {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;
