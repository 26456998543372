import styled from "styled-components";
import bg from "../../assets/bg/HeroVisual.png";
import tick from "../../assets/icon/tick.svg";

export const MainNewsletterContainer = styled.div`
  position: relative;
  min-height: 296px;
`;

export const BackgroundContainer = styled.div`
  background: #220234;
  opacity: 0.8;
  min-height: 296px;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const ImageContainer = styled.div`
  min-height: 296px;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-repeat: no-repeat;
  background-position: 62% 75%;
  background-image: url(${bg});
`;

export const NewsletterContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-height: 296px;
  z-index: 10;
  width: 100%;
`;

export const NewsletterTitle = styled.div`
  margin-bottom: 8px;
  font-size: 24px;
  font-weight: 500;
  width: 438px;
  text-align: center;
  line-height: 32px;

  @media only screen and (max-width: 600px) {
    width: 291px;
  }
`;

export const SubscriptionForm = styled.form`
  margin: 8px 0 8px 0;
`;

export const SubsContent = styled.div`
  width: 560px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;

  img {
    position: absolute;
    top: 18px;
    left: 18px;
    width: 12px;
  }

  button {
    width: 220px;
    margin: 0;
  }

  @media only screen and (max-width: 900px) {
    width: 500px;
  }

  @media only screen and (max-width: 600px) {
    width: 280px;
  }
`;

export const Input = styled.input`
  background-color: #220234;
  border: 1px solid #ffffff;
  height: 48px;
  width: 100%;
  justify-content: center;
  border-radius: 16px;
  font-family: "Montserrat", sans-serif;
  padding: 2px 0px 2px 48px;
  font-size: 14px;
  color: #ffffff;
  ::placeholder,
  ::-webkit-input-placeholder {
    color: #ffffff;
    font-weight: 500;
  }

  @media only screen and (max-width: 601px) {
    display: none;
  }
`;

export const InputMobile = styled.input`
  background-color: #220234;
  border: 1px solid #ffffff;
  height: 48px;
  width: 100%;
  justify-content: center;
  border-radius: 16px;
  font-family: "Montserrat", sans-serif;
  padding: 2px 0px 2px 48px;
  font-size: 14px;
  color: #ffffff;
  ::placeholder,
  ::-webkit-input-placeholder {
    color: #ffffff;
    font-weight: 500;
  }

  @media only screen and (min-width: 600px) {
    display: none;
  }
`;

export const SubsButton = styled.button`
  position: absolute;
  right: 12px;
  top: 8px;
  font-family: "Montserrat", sans-serif;
  width: 114px !important;
  height: 32px !important;
  border-radius: 12px;
  background-color: white;
  font-size: 12px;
  font-weight: 700;
  line-height: 0;
  color: #220234;
  border: none;
  cursor: pointer;
  &:disabled {
    background: grey;
  }

  @media only screen and (max-width: 601px) {
    display: none;
  }
`;

export const SubsButtonMobile = styled.button`
  position: absolute;
  right: 12px;
  top: 8px;
  font-family: "Montserrat", sans-serif;
  width: 56px !important;
  height: 32px;
  border-radius: 12px;
  background-color: white;
  font-size: 12px;
  font-weight: 700;
  line-height: 0;
  color: #220234;
  border: none;
  cursor: pointer;
  &:disabled {
    background: grey;
  }

  @media only screen and (min-width: 600px) {
    display: none;
  }
`;

export const CheckboxContainer = styled.div`
  margin-top: 8px;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  p {
    font-family: "Montserrat", sans-serif;
    color: #ffffff;
    font-weight: 500;
    font-size: 14px;
  }

  text-transform: none;

  @media only screen and (max-width: 900px) {
    margin-bottom: 0;
  }

  @media only screen and (max-width: 600px) {
    align-items: flex-start;

    text-align: left;

    p {
      font-family: "Montserrat", sans-serif;
      color: #ffffff;
      font-weight: 500;
      font-size: 12px;
      margin-top: 8px;
    }
  }
`;

export const Checkbox = styled.input`
  width: 20px;
  height: 20px;
  border-radius: 4px;
  border: 1px solid white;
  background-color: transparent;
  margin-right: 5px;
  appearance: none;
  cursor: pointer;

  &:checked {
    background-image: url(${tick});
    background-repeat: no-repeat;
    background-size: 16px 16px;
    background-position: center;
  }
  @media only screen and (max-width: 900px) {
    margin-top: 5px;
  }
`;
