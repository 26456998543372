import {
  Pwhite,
  ResponsiveContainer,
  SectionWrapper2,
} from "../../page/LandingPage.styled";
import Arrow from "../../assets/icon/arrow.svg";
import ArrowRight from "../../assets/icon/arrowRight-Pink.svg";
import {
  Article,
  ArticleDate,
  Button,
  Container,
  LeftContainer,
  NewsSectionContent,
  NewsSectionTitle,
  RightContainer,
  SubContainer,
  Title,
} from "./NewsSection.styled";
import "pure-react-carousel/dist/react-carousel.es.css";

import {
  CarouselProvider,
  Slider,
  Slide,
  ButtonBack,
  ButtonNext,
} from "pure-react-carousel";
import { useEffect, useState } from "react";
import { BuyOrStake } from "../04_EarnRewards/EarnSection.styled";

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

export default function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowDimensions;
}

export const NewsSection = (props: any) => {
  const { width } = useWindowDimensions();

  const onGoToArticle = (article: string) => {
    window.open(article, "_blank");
  };

  const computeMonth = (month: any) => {
    switch (month) {
      case 0:
        return "Jan";
      case 1:
        return "Fev";
      case 2:
        return "Mar";
      case 3:
        return "Avr";
      case 4:
        return "May";
      case 5:
        return "Jun";
      case 6:
        return "Jul";
      case 7:
        return "Aug";
      case 8:
        return "Sep";
      case 9:
        return "Oct";
      case 10:
        return "Nov";
      case 11:
        return "Dec";
    }
  };

  const computeTotalSlides = () => {
    if (width >= 1500) {
      return 6;
    } else if (width >= 900 && width < 1500) {
      return 8;
    } else return 9;
  };

  return (
    <SectionWrapper2>
      <ResponsiveContainer>
        <Container>
          <LeftContainer>
            <NewsSectionTitle>Latest news</NewsSectionTitle>
            <NewsSectionContent>
              DISCOVER THE LATEST UPDATES ABOUT DOGAMÍ AND ITS ECOSYSTEM
            </NewsSectionContent>

            <a
              href={"https://dogami.medium.com/"}
              target="_blank"
              rel="noreferrer"
              style={{ marginTop: "16px" }}
            >
              <BuyOrStake>
                <Pwhite>
                  <u className="stake-doga">READ MORE ON MEDIUM</u>
                  <img src={Arrow} className="arrow-white" alt="white-arrow" />
                  <img
                    src={ArrowRight}
                    className="arrow-red"
                    alt="white-arrow"
                  />
                </Pwhite>
              </BuyOrStake>
            </a>
          </LeftContainer>
          <RightContainer>
            {props.articles && props.articles.length > 4 && (
              <CarouselProvider
                naturalSlideWidth={100}
                naturalSlideHeight={100}
                totalSlides={computeTotalSlides() as number}
                visibleSlides={width >= 600 ? 4 : 5}
                step={1}
                dragEnabled={true}
                touchEnabled={true}
              >
                <Slider>
                  <Slide index={0}>
                    <Article
                      onClick={() => onGoToArticle(props.articles[0].link)}
                    >
                      <img src={props.articles[0].image} alt="image" />
                      <SubContainer>
                        <div>
                          <ArticleDate>
                            {props.articles[0].date.getDate()}{" "}
                            {computeMonth(props.articles[0].date.getMonth())}
                          </ArticleDate>
                          <Title>
                            {props.articles[0].title.length > 60
                              ? props.articles[0].title.substring(0, 60) + "..."
                              : props.articles[0].title}
                          </Title>
                        </div>
                        <Button>READ MORE</Button>
                      </SubContainer>
                    </Article>
                  </Slide>

                  <Slide index={1}>
                    <Article
                      onClick={() => onGoToArticle(props.articles[1].link)}
                    >
                      <img src={props.articles[1].image} alt="image" />
                      <SubContainer>
                        <ArticleDate>
                          {props.articles[1].date.getDate()}{" "}
                          {computeMonth(props.articles[1].date.getMonth())}
                        </ArticleDate>
                        <Title>
                          {props.articles[1].title.length > 60
                            ? props.articles[1].title.substring(0, 60) + "..."
                            : props.articles[1].title}
                        </Title>{" "}
                        <Button>READ MORE</Button>
                      </SubContainer>
                    </Article>
                  </Slide>
                  <Slide index={2}>
                    <Article
                      onClick={() => onGoToArticle(props.articles[2].link)}
                    >
                      <img src={props.articles[2].image} alt="image" />
                      <SubContainer>
                        <ArticleDate>
                          {props.articles[2].date.getDate()}{" "}
                          {computeMonth(props.articles[2].date.getMonth())}
                        </ArticleDate>
                        <Title>
                          {props.articles[2].title.length > 60
                            ? props.articles[2].title.substring(0, 60) + "..."
                            : props.articles[2].title}
                        </Title>
                        <Button>READ MORE</Button>
                      </SubContainer>
                    </Article>
                  </Slide>
                  <Slide index={3}>
                    <Article
                      onClick={() => onGoToArticle(props.articles[3].link)}
                    >
                      <img src={props.articles[3].image} alt="image" />
                      <SubContainer>
                        <ArticleDate>
                          {props.articles[3].date.getDate()}{" "}
                          {computeMonth(props.articles[3].date.getMonth())}
                        </ArticleDate>
                        <Title>
                          {props.articles[3].title.length > 60
                            ? props.articles[3].title.substring(0, 60) + "..."
                            : props.articles[3].title}
                        </Title>
                        <Button>READ MORE</Button>
                      </SubContainer>
                    </Article>
                  </Slide>
                  <Slide index={4}>
                    <Article
                      onClick={() => onGoToArticle(props.articles[4].link)}
                    >
                      <img src={props.articles[4].image} alt="image" />
                      <SubContainer>
                        <ArticleDate>
                          {props.articles[4].date.getDate()}{" "}
                          {computeMonth(props.articles[4].date.getMonth())}
                        </ArticleDate>
                        <Title>
                          {props.articles[4].title.length > 60
                            ? props.articles[4].title.substring(0, 60) + "..."
                            : props.articles[4].title}
                        </Title>
                        <Button>READ MORE</Button>
                      </SubContainer>
                    </Article>
                  </Slide>
                </Slider>

                <ButtonBack className="my-button-back" disabled={false}>
                  <img src={Arrow} alt="red-arrow" />
                </ButtonBack>
                <ButtonNext className="my-button-forward" disabled={false}>
                  <img src={Arrow} alt="arrow" />
                </ButtonNext>
              </CarouselProvider>
            )}
          </RightContainer>
        </Container>
      </ResponsiveContainer>
    </SectionWrapper2>
  );
};
