export const MediumIcon = ({ iconClass }: any) => {
  const computeIconClass = () => {
    return iconClass ? iconClass : "fill-white";
  };

  return (
    <svg
      className={`${computeIconClass()}`}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
    >
      <path
        fillRule="nonzero"
        d="M22.4,0 L1.6,0 C1.175656,0 0.7686872,0.1685712 0.4686288,0.4686288 C0.1685712,0.7686872 0,1.175656 0,1.6 L0,22.4 C0,22.82432 0.1685712,23.23128 0.4686288,23.53136 C0.7686872,23.83144 1.175656,24 1.6,24 L22.4,24 C22.82432,24 23.23128,23.83144 23.53136,23.53136 C23.83144,23.23128 24,22.82432 24,22.4 L24,1.6 C24,1.175656 23.83144,0.7686872 23.53136,0.4686288 C23.23128,0.1685712 22.82432,0 22.4,0 Z M19.9384,5.6864 L18.6512,6.92 C18.59664,6.961496 18.55448,7.017112 18.5292,7.080848 C18.504,7.144584 18.49664,7.214 18.508,7.2816 L18.508,16.3496 C18.49656,16.4172 18.50384,16.48664 18.52912,16.5504 C18.55432,16.61416 18.59656,16.66976 18.6512,16.7112 L19.908,17.9456 L19.908,18.216 L13.588,18.216 L13.588,17.9456 L14.8896,16.68 C15.0184,16.552 15.0184,16.5144 15.0184,16.3184 L15.0184,8.9896 L11.3976,18.1896 L10.9088,18.1896 L6.6904,8.9896 L6.6904,15.1496 C6.673368,15.27744 6.685544,15.40752 6.726,15.53 C6.766464,15.65248 6.83416,15.76424 6.924,15.8568 L8.6176,17.9104 L8.6176,18.1816 L3.8176,18.1816 L3.8176,17.9104 L5.5112,15.8568 C5.600288,15.76416 5.66644,15.65192 5.704368,15.52912 C5.742288,15.40632 5.750928,15.27632 5.7296,15.1496 L5.7296,8.0248 C5.739104,7.927456 5.725592,7.82924 5.69016,7.73808 C5.65472,7.64692 5.598352,7.565368 5.5256,7.5 L4.0184,5.6864 L4.0184,5.416 L8.6928,5.416 L12.3056,13.336 L15.4824,5.416 L19.9376,5.416 L19.9384,5.6864 Z"
      />
    </svg>
  );
};
