import styled from "styled-components";

export const MainWrapper = styled.div`
  font-family: "BonnBF-Regular", "BonnBF-Regular", -apple-system,
    BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, BonnBF-Regular, Cantarell,
    "Open Sans", "Helvetica Neue", sans-serif;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  background: linear-gradient(135deg, #220234 0, #2f0341 75%, #370449 100%);

  color: #ffffff;
  min-height: 100vh;

  a {
    text-decoration: none;
  }
`;

export const LayoutWrapper = styled.div`
  min-height: 100vh;
`;
