export const MenuIcon = ({ iconClass }: any) => {
  const computeIconClass = () => {
    return iconClass ? iconClass : "";
  };

  return (
    <svg
      className={`${computeIconClass()}`}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
    >
      <path
        fillRule="nonzero"
        d="M10.6451114,19.3185038 L22.8993347,19.3185038 C23.5150038,19.3185038 24.0041409,18.8220706 24.0041409,18.2127981 C24.0041409,17.597229 23.5171027,17.0975975 22.8993347,17.0975975 L10.6451114,17.0975975 C10.0295123,17.0975975 9.55,17.6014267 9.55,18.2127981 C9.55,18.8178729 10.0316211,19.3185038 10.6451114,19.3185038 Z M1.10260735,7.22090626 L18.1137938,7.22090626 C18.7272641,7.22090626 19.2068063,6.72453306 19.2068063,6.11525055 C19.2068063,5.49754257 18.729363,5 18.1137938,5 L1.10260735,5 C0.489107105,5 0,5.50386917 0,6.11525055 C0,6.72031533 0.491215971,7.22090626 1.10260735,7.22090626 Z M1.09300251,13.269685 L22.9069875,13.269685 C23.5225566,13.269685 24,12.7733118 24,12.1640293 C24,11.5463204 23.5246555,11.0487788 22.9069875,11.0487788 L1.09300251,11.0487788 C0.479505266,11.0487788 0,11.5505371 0,12.1640293 C0,12.7669852 0.481613133,13.269685 1.09300251,13.269685 Z"
      />
    </svg>
  );
};
