export const PenIcon = ({ iconClass }: any) => {
  const computeIconClass = () => {
    return iconClass ? iconClass : "fill-white";
  };

  return (
    <svg
      className={`${computeIconClass()}`}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
    >
      <path
        fillRule="nonzero"
        d="M14.953215,4.68315278 C14.5272151,4.25713104 13.8364515,4.25713104 13.4104516,4.68315278 C12.9844517,5.10918544 12.9844517,5.79990533 13.4104516,6.22593799 L14.953215,4.68315278 Z M17.7740873,10.5895627 C18.2000872,11.0155627 18.8908507,11.0155627 19.3168507,10.5895627 C19.7428506,10.1635628 19.7428506,9.4728211 19.3168507,9.04678844 L17.7740873,10.5895627 Z M6.54547091,21.8181791 L6.75941997,22.8879244 C6.97060902,22.8457062 7.16457263,22.7418517 7.31686351,22.5895608 L6.54547091,21.8181791 Z M1.09092634,22.909088 L0.0212028801,22.6951608 C-0.0503312901,23.0527607 0.0616210553,23.4225788 0.319533741,23.6804697 C0.577457335,23.9383606 0.947210002,24.0503969 1.3048754,23.9788333 L1.09092634,22.909088 Z M2.18183526,17.4545434 L1.41044265,16.6831617 C1.25815177,16.8354526 1.15435179,17.0294162 1.11211179,17.2406162 L2.18183526,17.4545434 Z M18.545469,1.09090973 L19.3168507,0.31951713 C18.8908507,-0.10650571 18.2000872,-0.10650571 17.7740873,0.31951713 L18.545469,1.09090973 Z M22.9091046,5.45454539 L23.6804863,6.22593799 C24.1064862,5.79990533 24.1064862,5.10918544 23.6804863,4.68315278 L22.9091046,5.45454539 Z M13.4104516,6.22593799 L17.7740873,10.5895627 L19.3168507,9.04678844 L14.953215,4.68315278 L13.4104516,6.22593799 Z M6.33152185,20.7484338 L0.876977286,21.8393427 L1.3048754,23.9788333 L6.75941997,22.8879244 L6.33152185,20.7484338 Z M2.16064981,23.1230152 L3.25155872,17.6684707 L1.11211179,17.2406162 L0.0212028801,22.6951608 L2.16064981,23.1230152 Z M2.95322786,18.2259251 L19.3168507,1.86230233 L17.7740873,0.31951713 L1.41044265,16.6831617 L2.95322786,18.2259251 Z M17.7740873,1.86230233 L22.1377229,6.22593799 L23.6804863,4.68315278 L19.3168507,0.31951713 L17.7740873,1.86230233 Z M22.1377229,4.68315278 L5.77407831,21.0467974 L7.31686351,22.5895608 L23.6804863,6.22593799 L22.1377229,4.68315278 Z"
      />
    </svg>
  );
};
