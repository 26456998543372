import {
  ResponsiveContainer,
  SectionWrapper,
} from "../../page/LandingPage.styled";

import uniswap from "../../assets/image/uniswap.png";

import mexclogo from "../../assets/image/mexc.png";

import bitpanda from "../../assets/image/bitpanda.png";

import quickswap from "../../assets/image/quickswap.png";

import gateLogo from "../../assets/image/gate.png";

import metamask from "../../assets/image/metamask.png";

import trustWallet from "../../assets/image/trustwallet.png";

import transak from "../../assets/image/transak.png";

import { EarnSectionWrapper } from "./EarnSection.styled";

import { ArrowIcon } from "./ArrowIcon";

import { toast } from "react-toastify";

export const copyToClipboard = async (text: string) => {
  try {
    return await toast.promise(() => navigator.clipboard.writeText(text), {
      success: "Copied to clipboard!",
      error: "Error while copying address : " + text,
    });
  } catch (error) {
    return false;
  }
};

export const EarnSection = () => {
  return (
    <SectionWrapper>
      <ResponsiveContainer style={{ overflow: "visible" }}>
        <EarnSectionWrapper>
          <div className="my-12 flex w-full flex-col items-center justify-center">
            <div className="text-md uppercase text-white">how to buy $DOGA</div>

            <div className="mt-12 flex w-full flex-col items-center justify-center font-montserrat text-xs laptop:flex-row">
              <div
                onClick={() =>
                  window.open(
                    "https://portfolio.metamask.io/networks/137/tokens/dogami/0x2f3e306d9f02ee8e8850f9040404918d0b345207",
                    "_blank"
                  )
                }
                className="flex h-32 w-72 cursor-pointer flex-col items-center justify-center gap-4 rounded-t-lg border border-white-10 px-8 py-4 text-center text-xs font-semibold uppercase text-white laptop:rounded-l-lg laptop:rounded-tr-none"
              >
                <div className="flex h-full w-full items-center justify-center gap-4">
                  <img
                    className="flex w-36 transition  duration-200 hover:scale-105"
                    src={metamask}
                    alt="metamask"
                  />
                </div>
              </div>

              <div
                onClick={() =>
                  window.open("https://trustwallet.com/buy-crypto", "_blank")
                }
                className="flex h-32 w-72 cursor-pointer flex-col items-center justify-center gap-4 rounded-t-lg border border-white-10 px-8 py-4 text-center text-xs font-semibold uppercase text-white laptop:rounded-l-lg laptop:rounded-tr-none"
              >
                <div className="flex h-full w-full items-center justify-center gap-4">
                  <img
                    className="flex w-36 transition  duration-200 hover:scale-105"
                    src={trustWallet}
                    alt="trustWallet"
                  />
                </div>
              </div>

              <div
                onClick={() =>
                  window.open(
                    "https://marketplace.dogami.com/?openBuyDogaModal=true",
                    "_blank"
                  )
                }
                className="flex h-32 w-72 cursor-pointer flex-col items-center justify-center gap-4 rounded-t-lg border border-white-10 px-8 py-4 text-center text-xs font-semibold uppercase text-white laptop:rounded-l-lg laptop:rounded-tr-none"
              >
                <div className="flex h-full w-full items-center justify-center gap-4">
                  <img
                    className="flex w-36 transition  duration-200 hover:scale-105"
                    src={transak}
                    alt="transak"
                  />
                </div>
              </div>

              <div
                onClick={() =>
                  window.open(
                    "https://www.dextools.io/app/en/polygon/pair-explorer/0x6d89b2c205b12d7b118bcea47c6e8ff7fe00ba69",
                    "_blank"
                  )
                }
                className="flex h-32 w-72 cursor-pointer flex-col items-center justify-center gap-4 rounded-t-lg border border-white-10 px-8 py-4 text-center text-xs font-semibold uppercase text-white laptop:rounded-l-lg laptop:rounded-tr-none"
              >
                <div className="flex h-full w-full items-center justify-center gap-4">
                  <img
                    className="flex w-36 transition  duration-200 hover:scale-105"
                    src={uniswap}
                    alt="doga"
                  />
                </div>
              </div>
            </div>

            <div className="mb-6 flex w-full flex-col items-center justify-center font-montserrat text-xs laptop:flex-row">
              <div
                onClick={() =>
                  window.open("https://www.gate.io/trade/DOGA_USDT", "_blank")
                }
                className="flex h-32 w-72 cursor-pointer flex-col items-center justify-center gap-4 border border-white-10 px-8 py-4 text-center text-xs font-semibold uppercase text-white"
              >
                <img
                  className="flex w-44 transition  duration-200 hover:scale-105"
                  src={gateLogo}
                  alt="doga"
                />
              </div>

              <div
                onClick={() =>
                  window.open(
                    "https://www.mexc.com/exchange/DOGA_USDT",
                    "_blank"
                  )
                }
                className="flex h-32 w-72 cursor-pointer flex-col items-center justify-center gap-4 border border-white-10 px-8 py-4 text-center text-xs font-semibold uppercase text-white"
              >
                <img
                  className="flex w-44 transition  duration-200 hover:scale-105"
                  src={mexclogo}
                  alt="mexclogo"
                />
              </div>

              <div
                onClick={() =>
                  window.open(
                    "https://www.bitpanda.com/en/prices/dogami-doga",
                    "_blank"
                  )
                }
                className="flex h-32 w-72 cursor-pointer flex-col items-center justify-center gap-4 border border-white-10 px-8 py-4 text-center text-xs font-semibold uppercase text-white"
              >
                <img
                  className="ml-4 flex w-44 transition duration-200 hover:scale-105"
                  src={bitpanda}
                  alt="bitpanda"
                />
              </div>

              <div
                onClick={() =>
                  window.open(
                    "https://quickswap.exchange/#/swap?currency0=ETH&currency1=0x2F3E306d9F02ee8e8850F9040404918d0b345207&swapIndex=0",
                    "_blank"
                  )
                }
                className="flex h-32 w-72 cursor-pointer flex-col items-center justify-center gap-4 border border-white-10 px-8 py-4 text-center text-xs font-semibold uppercase text-white"
              >
                <img
                  className="ml-4 flex w-44 transition duration-200 hover:scale-105"
                  src={quickswap}
                  alt="quickswap"
                />
              </div>
            </div>

            <div className="mt-12 flex w-full items-center justify-center text-center font-montserrat text-sm uppercase text-white">
              $DOGA holder? You can now:
            </div>

            <div className="mt-8 flex w-full flex-col items-center justify-center gap-4 font-montserrat text-xs laptop:flex-row laptop:gap-8">
              <div
                onClick={() => {
                  window.open(
                    "https://opensea.io/dogami_Deployer/created",
                    "_blank"
                  );
                }}
                className="flex w-full cursor-pointer items-center justify-center rounded-2xl border border-white-10 bg-purple p-6 font-semibold uppercase text-white hover:border-white hover:text-white hover:underline laptop:w-1/3"
              >
                Buy Dogamí
                <ArrowIcon iconClass="w-4 fill-pink ml-3"></ArrowIcon>
              </div>
              <div
                onClick={() => {
                  window.open("https://marketplace.dogami.com/shop", "_blank");
                }}
                className="flex w-full cursor-pointer items-center justify-center rounded-2xl border border-white-10 bg-purple p-6 font-semibold uppercase text-white hover:border-white hover:text-white hover:underline laptop:w-1/3"
              >
                shop <ArrowIcon iconClass="w-4 fill-pink ml-3"></ArrowIcon>
              </div>
            </div>
          </div>
        </EarnSectionWrapper>
      </ResponsiveContainer>
    </SectionWrapper>
  );
};
