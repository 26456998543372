import styled from "styled-components";
import bg from "../../assets/Trailer-DogamiAcademy.jpg";

export const RaiseSectionWrapper = styled.div`
  display: flex;
  min-height: 100vh;
  height: 100vh;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 5%;

  @media only screen and (max-width: 900px) {
    min-height: 50vh;
    height: 100%;
    flex-direction: column-reverse;
    padding: 0 5%;
  }
`;

export const DownloadIcons = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  margin: 16px 0 16px 0;

  img {
    width: 40px;
  }
`;

export const TrailerContainer = styled.div`
  margin-top: 8px;
  border-radius: 8px;
  height: 160px;
  width: 272px;
  cursor: pointer;

  background-image: url(${bg});
  background-repeat: no-repeat;
  background-size: 272px 160px;
  background-position: center;

  position: relative;

  p {
    text-align: center;
  }

  .play-button {
    z-index: 5;
    position: absolute;
    top: 48px;
    left: 126px;
    width: 18px;
    cursor: pointer;
    margin-top: 24px;
  }

  p {
    position: absolute;
    cursor: pointer;
    top: 30px;
    left: 68px;
    background: white;
    padding: 8px 16px;
    color: #220234;
    font-weight: 900;
    border-radius: 12px;
    margin-top: 36px;

    &:hover {
      color: #ff0060;
    }
  }

  @media only screen and (max-width: 900px) {
    display: none;
  }
`;

export const RingWrapper = styled.div`
  z-index: 5;
  position: absolute;
  top: 50px;
  left: 102px;

  img {
    width: 64px;
  }
`;

export const RingWrapperMobile = styled.div`
  z-index: 10;
  position: absolute;
  top: -15px;
  left: -20px;

  .ring-mobile {
    width: 66px;
    z-index: 10;
    position: absolute;
    top: 40px;
    left: 120px;
    cursor: pointer;
    margin-top: 24px;
    }
  }
`;

export const PurpleButtonShadow = styled.div`
  z-index: 1;
  width: 50px;
  height: 50px;
  position: absolute;
  top: 57px;
  left: 109px;
  background: #220234;
  border-radius: 50%;
  box-shadow: inset 0 0 32px #d9bee8;
`;

export const BottomTrailerContainer = styled.div`
  margin-top: 8px;
  border-radius: 8px;
  height: 160px;
  width: 272px;
  cursor: pointer;

  background-image: url(${bg});
  background-repeat: no-repeat;
  background-size: 272px 160px;
  background-position: center;

  position: relative;

  .mobile-play-button {
    z-index: 10;
    position: absolute;
    top: 48px;
    left: 126px;
    width: 18px;
    cursor: pointer;
    margin-top: 24px;
  }

  p {
    text-align: center;
  }

  a {
    z-index: 10;
    position: absolute;
    cursor: pointer;
    top: 104px;
    left: 68px;
    background: white;
    padding: 8px 16px;
    color: #220234;
    font-weight: 900;
    border-radius: 12px;
    margin-top: 36px;

    &:hover {
      color: #ff0060;
    }
  }

  @media only screen and (min-width: 300px) {
    margin-top: 70px;
  }

  @media only screen and (min-width: 400px) {
    margin-top: 130px;
  }

  @media only screen and (min-width: 500px) {
    margin-top: 200px;
  }

  @media only screen and (min-width: 600px) {
    margin-top: 320px;
  }

  @media only screen and (min-width: 900px) {
    display: none;
  }
`;

export const PhoneArea = styled.div`
  position: relative;
  overflow: hidden;
  width: 50%;
  max-height: 1000px;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 2%;
  img {
    position: absolute;
    right: 0;
    max-width: 350px;
    height: auto;
  }
  img:first-child {
    left: 5%;
    bottom: 10%;
    animation: move5 5s linear alternate infinite;
    @keyframes move5 {
      from {
        transform: translateY(0);
      }
      to {
        transform: translateY(-5%);
      }
    }
  }
  img:last-child {
    left: 35%;
    top: 10%;
    animation: move6 5s linear alternate infinite;
    @keyframes move6 {
      from {
        transform: translateY(0);
      }
      to {
        transform: translateY(5%);
      }
    }
  }
  @media only screen and (max-width: 1440px) {
    img {
      width: 60%;
    }
    img:first-child {
      left: 5%;
    }
    img:last-child {
      right: 0;
    }
  }
  @media only screen and (max-width: 900px) {
    min-height: 40vh;
    width: 100%;
    overflow: visible;
    img {
      width: 70%;
    }
    img:first-child {
      left: 15%;
      top: 30%;
      overflow: hidden !important;
    }
    img:last-child {
      left: 45%;
      top: 5%;
    }
  }
  @media only screen and (max-width: 810px) {
    img {
      width: 55%;
    }
    img:first-child {
      left: 10%;
      top: 25%;
    }
    img:last-child {
      left: 45%;
      top: 0%;
    }
  }
  @media only screen and (max-width: 601px) {
    img:first-child {
      left: 5%;
      top: 20%;
    }
    img:last-child {
      left: 40%;
      top: 0%;
    }
  }
  @media only screen and (max-width: 428px) {
    min-height: 45vh;
    img:first-child {
      left: 5%;
      top: 20%;
      overflow: hidden !important;
    }
    img:last-child {
      left: 40%;
      top: 0%;
    }
  }
  @media only screen and (max-width: 375px) {
    min-height: 50vh;
  }
  @media only screen and (max-width: 320px) {
    img:first-child {
      left: 5%;
      top: 15%;
      overflow: hidden !important;
    }
    img:last-child {
      left: 40%;
      top: -10%;
    }
  }
`;

export const GameplayOverviewLink = styled.a`
  color: white;
  margin-top: 8px;
  text-decoration: underline !important;
  font-family: "Montserrat";
  width: fit-content;

  :hover {
    color: #551a8b;
  }
`;
