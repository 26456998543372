import styled from "styled-components";

export const TitleWrapper = styled.div`
  margin: 80px 32px 32px 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 4px;

  @media only screen and (max-width: 640px) {
    text-align: left;
    align-items: flex-start;
    margin: 48px 16px 16px 16px;
  }
`;

export const PeopleItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  text-align: center;
  margin: 16px;
  min-height: 320px;
  width: 224px;

  @media only screen and (max-width: 1200px) {
    min-height: 0px;
    justify-content: center;
  }

  img {
    border-radius: 50%;
    width: 128px;
    margin: 8px 0 8px 0;
  }

  h2 {
    color: #11cbed;
    font-size: 16px;
    line-height: 24px;
  }

  p:not(:last-child) {
    margin: 8px 0;
    text-transform: uppercase;
    font-size: 12px;
    line-height: 16px;
    height: 32px;
  }

  p:last-child {
    opacity: 0.4;
    text-transform: none !important;
    font-family: "Montserrat", sans-serif;
    margin-top: 0;
    width: 172px;
  }
`;

export const PeopleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 0 16px;

  @media only screen and (max-width: 1200px) {
    flex-direction: column;
  }
`;
