import Alexis from "../../assets/investors/ABonte@2x.jpg";
import Aly from "../../assets/investors/AMadhavji@2x.jpg";
import Animoca from "../../assets/investors/Animoca.svg";
import Blockchain from "../../assets/investors/bff.svg";
import Bpi from "../../assets/investors/BPI.svg";
import Draper from "../../assets/investors/Draper.svg";
import Neville from "../../assets/investors/NBrody@2x.jpg";
import Sebastien from "../../assets/investors/SBorget@2x.jpg";
import Tezos from "../../assets/investors/Tezos.svg";
import Sandbox from "../../assets/investors/TheSandbox.svg";
import UbiSoft from "../../assets/investors/Ubisoft.svg";
import Xange from "../../assets/investors/Xange.svg";
import Yat from "../../assets/investors/YSui@2x.jpg";

import {
  H2,
  H3,
  InvestorSectionWrapper,
  ResponsiveContainer,
} from "../../page/LandingPage.styled";
import { LogoImgArea, Logos } from "../10_Featured/FeaturedSection.styled";

import {
  PeopleItemWrapper,
  PeopleWrapper,
  TitleWrapper,
} from "./NewInvestor.styled";

const investors = [
  {
    img: Sebastien,
    name: "SÉBASTIEN BORGET",
    role: "COO & CO-FOUNDER",
    company: "THE SANDBOX",
    position: "President of the Blockchain Game Alliance",
  },
  {
    img: Neville,
    name: "NEVILLE BRODY",
    role: "Creative Director & Typographer",
    position: "MoMa New-York Permanent Artist",
  },
  {
    img: Yat,
    name: "YAT SIU",
    role: "Chairman Of The Board",
    company: "Animoca Brands",
    position: "Young Global Leader by the World Economic Forum",
  },
  {
    img: Alexis,
    name: "ALEXIS BONTE",
    role: "COO ",
    company: "Stillfront Group",
    position: "Gaming Executive & Serial Entrepreneur",
  },
  {
    img: Aly,
    name: "ALY MADHAVJI",
    role: "Managing Partner ",
    company: "Blockchain Founders Fund",
    position: "Tech Entrepreneur & Investor",
  },
];

const companies = [
  { url: UbiSoft, name: "Ubisoft" },
  { url: Sandbox, name: "Sandbox" },
  { url: Animoca, name: "Animoca" },
  { url: Tezos, name: "Tezos" },
  { url: Bpi, name: "Bpi" },
  { url: Blockchain, name: "Blockchain" },
  { url: Xange, name: "Xange" },
  { url: Draper, name: "Draper" },
];

const PeopleItem = (item: any) => {
  return (
    <>
      <img src={item.img} alt={item.name} />
      <H2>{item.name}</H2>
      <p>
        {item.role} <br /> {item.company}
      </p>
      <p>{item.position}</p>
    </>
  );
};

export const NewInvestorSection = () => {
  return (
    <>
      <InvestorSectionWrapper>
        <ResponsiveContainer>
          <TitleWrapper>
            <H2>BACKED & SUPPORTED BY THE BEST IN CLASS</H2>
            <H3>Trusted by more than 50 investors</H3>
          </TitleWrapper>
          <PeopleWrapper>
            {investors.map((investor, i) => (
              <PeopleItemWrapper key={i}>
                <PeopleItem {...investor}></PeopleItem>
              </PeopleItemWrapper>
            ))}
          </PeopleWrapper>
          <div
            style={{
              marginBottom: "32px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
            }}
          >
            <div
              style={{
                maxWidth: "1324px",
                display: "flex",
                justifyContent: "center",
                width: "100%",
                alignItems: "center",
              }}
            >
              <LogoImgArea>
                {companies.map((c, i) => (
                  <Logos key={i}>
                    <img style={{ width: "96px" }} src={c.url} alt={c.name} />
                  </Logos>
                ))}
              </LogoImgArea>
            </div>
          </div>
        </ResponsiveContainer>
      </InvestorSectionWrapper>
    </>
  );
};
