import i18n, { InitOptions } from "i18next";

import { initReactI18next } from "react-i18next";

import HttpBackend from "i18next-http-backend";

import LanguageDetector from "i18next-browser-languagedetector";

// Define the configuration options using the InitOptions interface from i18next
const i18nConfig: InitOptions = {
  fallbackLng: "en", // Fallback language if the detected language is not available
  supportedLngs: ["en", "fr", "de"], // List of supported languages
  backend: {
    loadPath: "/locales/{{lng}}/common.json", // Path to your translation files
  },
  detection: {
    order: ["path", "cookie", "header"], // Order of language detection
    caches: ["cookie"], // Caching method for detected languages
  },
  react: {
    useSuspense: false, // Disable React Suspense
  },
};

i18n
  .use(HttpBackend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init(i18nConfig);

export default i18n;
